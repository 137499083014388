import { FC, useMemo } from 'react'
import dayjs from 'dayjs'
import { Trans, useTranslation } from 'react-i18next'

import { Icon } from '@fto/icons'
import { Button, Flex, Modal, Typography, addModal, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { User } from '@root/types/user'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser, $getUserSubscriptionStatus } from '@root/store/auth/selectors'

import styles from './components/modals.module.scss'

type Props = {
    trialPeriodEnd: User['trialPeriodEnd']
    chargeDate: Date | null
}

export const SuspendSubscriptionModal: FC<Props> = ({ trialPeriodEnd, chargeDate }) => {
    const { t } = useTranslation()

    const { isTrialExpired } = useAppSelector($getUser)
    const { isActive } = useAppSelector($getUserSubscriptionStatus)

    const date = useMemo(() => {
        return dayjs(isTrialExpired ? chargeDate : trialPeriodEnd, 'MM/DD/YYYY h:mm A')
            .locale('en')
            .format('MMMM DD, YYYY')
    }, [trialPeriodEnd, chargeDate, isTrialExpired])

    const nextStep = () => {
        addModal(MODAL_NAMES.userProfile.survey)
        removeModal(MODAL_NAMES.userProfile.suspendSubscription)
    }
    return (
        <Modal name={MODAL_NAMES.userProfile.suspendSubscription} size='sm'>
            <Modal.Header>
                <Flex direction='column' alignItems='center' gap={24}>
                    <Icon name='face-frown' size={72} />
                    <Typography type='h3-bold' align='center'>
                        {isActive ? t('profile.modals.cancelHeader') : t('profile.modals.suspendHeader')}
                    </Typography>
                </Flex>
            </Modal.Header>
            <Modal.Content className={styles.line}>
                {chargeDate !== null && (
                    <Typography color='gray-900' className={styles.modalText} type='text-regular'>
                        <Trans
                            i18nKey={isActive ? 'profile.modals.cancelText' : 'profile.modals.suspendText'}
                            components={{ date: <Typography type='text-regular'>{date}</Typography> }}
                        />
                    </Typography>
                )}
            </Modal.Content>
            <Modal.Controls>
                <Button
                    label={t('global.cancel')}
                    onClick={() => removeModal(MODAL_NAMES.userProfile.suspendSubscription)}
                    type='secondary'
                />
                <Button
                    label={isActive ? t('profile.cancelSubscription') : t('profile.modals.suspendSubscription')}
                    onClick={nextStep}
                    type='primary'
                    classNames={{ content: styles.red }}
                />
            </Modal.Controls>
        </Modal>
    )
}
