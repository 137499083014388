import { TChartOptions } from '../../charting/ChartBasicClasses'
import { TChart } from '../../charting/chart_classes/BasicChart'
import { DateUtils, TDateTime } from '../../delphi_compatibility/DateUtils'
import { TColor, TPenStyle, TPoint, TRect } from '../../delphi_compatibility/DelphiBasicTypes'
import { IGPSolidBrush } from '../../delphi_compatibility/DelphiGDICompatibility'
import { TGdiPlusCanvas } from '../../drawing_interface/GdiPlusCanvas'
import { TIndexPos } from '../../drawing_interface/GraphicObjects'
import { TIndicator } from '../../extension_modules/indicators/IndicatorUnit'
import { TVisibleIndexBuffer } from '../../extension_modules/indicators/VisibleIndexBuffer'
import { TTradePositionType } from '../../ft_types/common/BasicClasses/BasicEnums'
import { TTradePosition } from '../../ft_types/common/BasicClasses/TradePosition'
import { TSymbolData } from '../../ft_types/data/SymbolData'
import { TChunkStatus } from '../../ft_types/data/chunks/ChunkEnums'
import TDownloadableChunk from '../../ft_types/data/chunks/DownloadableChunk/DownloadableChunk'
import { TDataTypes } from '../../ft_types/data/data_arrays/DataDescriptionTypes'
import { TSearchMode } from '../../ft_types/data/data_arrays/FXDataArrays'
import { TDataAvailability } from '../../ft_types/data/data_downloading/DownloadRelatedEnums'
import GlobalOptions from '../../globals/GlobalOptions'
import GlobalProcessingCore from '../../globals/GlobalProcessingCore'
import GlobalProjectInfo from '../../globals/GlobalProjectInfo'
import { TTradePos } from '../../processing_core/TradePositionClasses/TradePosition'
import MouseTracker from '../../utils/MouseTracker'
import { TChartStyle } from '../auxiliary_classes_charting/ChartingEnums'
import { TChartWindow } from '../chart_windows/ChartWindow'
import { VisualLoader } from '@fto/lib/drawing_interface/VisualLoader'
import CommonConstants from '@fto/lib/ft_types/common/CommonConstants'
import { TBarChunk } from '@fto/lib/ft_types/data/chunks/BarChunk'
import { DownloadController } from '@fto/lib/ft_types/data/data_downloading/DownloadController'
import { TBasicPaintTool } from '@fto/lib/charting/paint_tools/BasicPaintTool'
import { GlobalNewsController } from '@fto/lib/News/GlobalNewsController'
import { News } from '@fto/lib/News/News'
import { CountryChartImages } from '@fto/countries'
import { SellBuyConnectorLine } from '@fto/lib/processing_core/TradePositionClasses/SellBuyConnectorLine'
import { ChartControl } from '@fto/chart_components/ChartControl'
import { MarketValues } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import { OrderLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/OrderLevel'
import GlobalServerSymbolInfo, { ServerSymbolInfo } from '@fto/lib/globals/GlobalServerSymbolInfo'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { NotImplementedError } from '@fto/lib/utils/common_utils'
import { TBarRecord } from '@fto/lib/ft_types/data/DataClasses/TBarRecord'
import { TDataRecordWithDate } from '@fto/lib/ft_types/data/DataClasses/TDataRecordWithDate'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import IBarAnchorPoint from '@fto/lib/globals/Definitions/IBarAnchorPoint'
import { EvChartLoadingEvents } from '../auxiliary_classes_charting/ChartingEvents'
import chartSettingsStore from '@fto/lib/store/chartSettings'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import { EBlockingLayerState } from '../auxiliary_classes_charting/Layers/ChartLayersEnums'
import { LayerBlockingChart } from '../auxiliary_classes_charting/Layers/LayerBlockingChart'
import { t } from 'i18next'
import { SelectableControl } from '@fto/lib/processing_core/TradePositionClasses/SelectableControl'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'

export class TMainChart extends TChart {
    public NewsImgs: any // Placeholder for TPngImageList
    private readonly VERTICAL_GRID_FONT = '12px Roboto Flex'

    private vMargin = 30
    private _symbolData!: TSymbolData
    private fMinGValue!: number
    private fMaxGValue!: number
    private _currVisibleStartDate!: number
    private _currVisibleEndDate!: number

    public isShowChunkDownloadLoading = false
    private _visualLoader: VisualLoader | undefined = undefined

    private get visualLoader(): VisualLoader {
        if (!this._visualLoader) {
            throw new StrangeError('VisualLoader is not initialized in MainChart')
        }
        return this._visualLoader
    }

    public shownNews: News[] = []
    public static toolListToCopy: TBasicPaintTool[] = []
    public marketValues = new MarketValues()

    constructor(chartWindow: TChartWindow) {
        DebugUtils.logTopic(ELoggingTopics.lt_Loading, 'MainChart constructor')
        super(chartWindow)

        this.SetChartOptions(new TChartOptions())

        this.SetName(`MainChart`)
        this.fMargins = new TRect(0, 0, 8, 0) // Modify as needed
        this.align = 'client' // Placeholder, adjust as needed for your UI framework
    }

    public Set_HTML_Canvas(canvas: HTMLCanvasElement): void {
        super.Set_HTML_Canvas(canvas)
        this._visualLoader = new VisualLoader(canvas)
        this.visualLoader.mainChart = this
        this.recalculateRightMargin()
        this.ChartWindow.initLayers()
        this.Events.EmitEvent(EvChartLoadingEvents.ce_OnCanvasInitialized, this)
    }

    public get SymbolData(): TSymbolData {
        if (!this._symbolData) {
            throw new StrangeError('SymbolData is not initialized in MainChart')
        }
        return this._symbolData
    }

    public get isDataLoaded(): boolean {
        // console.log('isDataLoaded in MainChart', this._symbolData, super.isDataLoaded)
        return this._symbolData && this._symbolData.IsSeeked && super.isDataLoaded && this.isChartOptionsInitialized
    }

    //TODO: move this logic into symbolData
    public updateMarketValues(): void {
        if (!this.SymbolData.IsSeeked) {
            throw new StrangeError('SymbolData is not seeked in MainChart')
        }

        this.marketValues.ask = this.SymbolData.ask
        this.marketValues.bid = this.SymbolData.bid
        this.marketValues.spread = this.SymbolData.Spread()
        this.marketValues.equity = GlobalProcessingCore.ProcessingCore.Equity
        this.marketValues.minimumPriceChange = this.SymbolData.symbolInfo.MinPoint
        this.marketValues.minimumDistanceToPrice = this.SymbolData.symbolInfo.MinDistToPrice
        this.marketValues.postDecimalDigits = this.SymbolData.symbolInfo.decimals
        this.marketValues.symbol = this.SymbolData.symbolInfo.SymbolName
        this.marketValues.pointValueForOneStandardLot = 1
    }

    public IsVisibleBarChunk(barChunk: TBarChunk): boolean {
        let result = false
        if (
            (barChunk.FirstDate <= this._currVisibleEndDate && barChunk.FirstDate >= this._currVisibleStartDate) ||
            (barChunk.LastPossibleDate <= this._currVisibleEndDate &&
                barChunk.LastPossibleDate >= this._currVisibleStartDate)
        ) {
            result = true
        }
        return result
    }

    copyPaintTools(): void {
        this.PaintTools.copyTools()
    }

    pastePaintTools(): void {
        this.PaintTools.pasteTools(this.ChartWindow)
    }

    PixelToPriceDiff(value: number): number {
        // Returns the price difference corresponding to the given pixel value
        return Math.abs(this.GetPriceFromY(0) - this.GetPriceFromY(value))
    }

    GetPriceFromY(y: number): number {
        // Converts a Y-coordinate (pixel) to the corresponding price value
        if (this.ChartOptions.FixedScale) {
            return (
                (-y + (this.GetPaintRect().Bottom + this.GetPaintRect().Top) / 2) /
                    this.ChartOptions.VerticalScale /
                    this.fVScale +
                this.ChartOptions.FixedScaleBaseLevel
            )
        } else {
            return (-y + this.GetPaintRect().Bottom - this.vMargin) / this.fVScale + this.fMinValue
        }
    }

    public GetPriceUnderMouse(event: MouseEvent | undefined = undefined): number {
        let localY: number
        if (event) {
            // Returns the price value corresponding to the mouse position
            localY = this.MouseToLocal(event).y
        } else {
            const localMouseCoords = this.GetCurrentMousePositionInLocalCoordinates()
            localY = localMouseCoords.y
        }
        return this.GetPriceFromY(localY)
    }

    public GetBarFromX(x: number): TBarRecord | null {
        const anchorPoint = this.GetAnchorPointFromX(x)
        return anchorPoint ? anchorPoint.bar : null
    }

    public GetAnchorPointFromX(x: number): IBarAnchorPoint | null {
        let paintRect

        if (this.IsPaintContextCacheInitialized) {
            paintRect = this.PaintContextCache.PaintRect
        } else {
            paintRect = this.GetPaintRect()
        }

        if (x < paintRect.Left || x > paintRect.Right) {
            return null //mouse is not on chart (maybe it is on the price scale)
        }

        // Retrieves the bar information for the given X-coordinate
        const index: number = this.GetIndexFromX(x, true)
        if (index < this.position || index > this.GetLastVisibleBarIndex() || !this.Bars.IsIndexValid(index)) {
            return null
        } else {
            const foundBar = this.Bars.GetItemByGlobalIndex(index)
            //TODO: get date that represents the anchor including offset and use this date for scrolling
            return { bar: foundBar, index: index, x: x }
        }
    }

    public GetLastBarInTesting(): TBarRecord | null {
        return this.Bars.LastItemInTesting
    }

    public GetCurrentMousePositionInLocalCoordinates(): TPoint {
        const tracker = MouseTracker.getInstance()
        const localMouseCoords = this.MouseToLocal(new TPoint(tracker.x, tracker.y))
        return new TPoint(localMouseCoords.x, localMouseCoords.y)
    }

    public GetAnchorPointUnderMouse(): IBarAnchorPoint | null {
        const localMouseCoords = this.GetCurrentMousePositionInLocalCoordinates()

        return this.GetAnchorPointFromX(localMouseCoords.x)
    }

    public GetBarUnderMouse(): TBarRecord | null {
        const anchorPointUnderMouse = this.GetAnchorPointUnderMouse()
        if (anchorPointUnderMouse && anchorPointUnderMouse.bar) {
            return anchorPointUnderMouse.bar
        } else {
            return null
        }
    }

    public GetBarIndexUnderMouse(): number | null {
        const anchorPointUnderMouse = this.GetAnchorPointUnderMouse()
        if (anchorPointUnderMouse) {
            return anchorPointUnderMouse.index
        } else {
            return null
        }
    }

    public GetAnchorPointByIndex(index: number): IBarAnchorPoint | null {
        const foundBar = this.Bars.GetItemByGlobalIndex(index)
        if (foundBar) {
            return { bar: foundBar, index: index, x: this.GetX(index) }
        }

        return null
    }

    public GetIndicatorUnderMouse(event: MouseEvent): [TVisibleIndexBuffer | null, number | null, TIndicator | null] {
        let buffer: TVisibleIndexBuffer | null = null
        let value: number | null = null
        let result: TIndicator | null = null

        if (!this._areDimensionsCalculated) {
            return [buffer, value, result]
        }

        const p = this.MouseToLocal(event)
        const index = this.GetMouseIndex()

        for (let i = this.fIndicators.Count - 1; i >= 0; i--) {
            const indicator = this.fIndicators.GetItem(i)
            if (indicator.IsVisible()) {
                for (let j = 0; j < indicator.VisibleBuffers.length; j++) {
                    buffer = indicator.VisibleBuffers[j]
                    if (buffer.CheckIndex(index) === TIndexPos.ip_Valid && buffer.IsVisible()) {
                        value = buffer.GetValue(index)
                        if (value !== buffer.EmptyValue && this.MouseInPriceRange(new TPoint(p.x, p.y), value)) {
                            result = indicator
                            return [buffer, value, result]
                        }
                    }
                }
            }
        }

        return [buffer, value, result]
    }

    public GetMediumPriceLevel(): number {
        // Returns the price level at the vertical middle of the paintable area
        return this.GetPriceFromY((this.GetPaintRect().Bottom + this.GetPaintRect().Top) / 2)
    }

    GetLastBarPrice(): number {
        const lastBar = this.GetLastBarInTesting()
        return lastBar ? lastBar.close : 0
    }

    UpdateLeftBarIndex(index: number): void {
        this.LeftPosition = index
        this.Paint()
    }

    public SetSymbolData(data: TSymbolData): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Loading, 'MainChart SetSymbol')

        this._symbolData = data

        if (data) {
            this.recalculateRightMargin()
        }
    }

    public recalculateRightMargin(): void {
        if (!this.is_HTML_Canvas_initialized() || !this._symbolData) return

        const context = this.CanvasContext

        context.font = GlobalOptions.Options.VERTICAL_GRID_FONT
        this.fMargins.Right = Math.max(
            8 + context.measureText('0.00000').width,
            8 + context.measureText(this.SymbolData.FormatPriceToStr(0)).width
        )
    }

    private get IsProfitChart(): boolean {
        return this.SymbolData && this.SymbolData.symbolInfo && this.SymbolData.symbolInfo.SymbolName === 'PROFIT'
    }

    private GetMinMaxValues(): { minValue: number; maxValue: number } {
        let minValue: number
        let maxValue: number

        if (this.IsProfitChart) {
            //TODO: implement logic for Profit chart here, see Delphi code or old code here
            throw new NotImplementedError('GetMinMaxValues for Profit chart is not implemented')
        } else {
            // Not profit chart
            minValue = this.Bars.GetMin(this.position, this.GetLastVisibleBarIndex(), TSearchMode.sm_Low)
            maxValue = this.Bars.GetMax(this.position, this.GetLastVisibleBarIndex(), TSearchMode.sm_High)
            return { minValue, maxValue }
        }
    }

    GetMinMaxOnPriceBorder(): { minValue: number; maxValue: number } {
        return { minValue: this.fMinGValue - this.fVPoint, maxValue: this.fMaxGValue + this.fVPoint }
    }

    public CalcDimensions(): void {
        super.CalcDimensions()

        this._areDimensionsCalculated = false
        const paintRect = this.IsPaintContextCacheInitialized ? this.PaintContextCache.PaintRect : this.GetPaintRect()

        if (
            !this._symbolData ||
            !this._bars ||
            !this._bars.FullMapDownloaded ||
            paintRect.Bottom - paintRect.Top < this.vMargin * 2 + 2
        ) {
            return
        }

        const minMaxValues = this.GetMinMaxValues() // Adapt this method to set fMinValue and fMaxValue
        this.fMinValue = minMaxValues.minValue
        this.fMaxValue = minMaxValues.maxValue

        if (this.fMinValue === this.fMaxValue && this.fMinValue !== CommonConstants.EMPTY_DATA_VALUE) {
            this.fMinValue -= 10
            this.fMaxValue += 10
        }

        if (
            this.fMinValue === CommonConstants.EMPTY_DATA_VALUE ||
            this.fMaxValue === CommonConstants.EMPTY_DATA_VALUE ||
            this.fMinValue >= this.fMaxValue
        ) {
            return
        }

        if (this.ChartOptions.FixedScale) {
            this.fVScale = this.ChartOptions.FixedVScale
        }

        if (!this.ChartOptions.FixedScale || this.fVScale === 0) {
            this.ChartOptions.VerticalScale = 1
            this.fVScale = (paintRect.Bottom - paintRect.Top - this.vMargin * 2) / (this.fMaxValue - this.fMinValue)

            if (this.ChartOptions.FixedScale) {
                this.ChartOptions.FixedVScale = this.fVScale
            }
        }

        const delta = this.IsProfitChart ? 10 : this.SymbolData.symbolInfo.MinPoint * 5
        let cnt = 0
        let v1 = 0
        this.fVPoint = delta

        while (this.fVPoint * this.fVScale * this.ChartOptions.VerticalScale < 32) {
            if (this.IsProfitChart) {
                if (cnt % 4 === 0) {
                    this.fVPoint = Math.round(this.fVPoint * 2.5)
                    v1 = this.fVPoint
                } else {
                    this.fVPoint += v1
                }
                cnt++
            } else {
                this.fVPoint += delta
            }
        }

        this.fVPoint = this.SymbolData.RoundPrice(this.fVPoint) // Adapt RoundPrice method

        this.fMinGValue = Math.trunc(this.GetPriceFromY(paintRect.Bottom) / this.fVPoint - 2) * this.fVPoint
        this.fMaxGValue = Math.trunc(this.GetPriceFromY(paintRect.Top) / this.fVPoint + 2) * this.fVPoint

        this._areDimensionsCalculated = true
    }

    //it is made public because VS Code gives an error (base class has ScaleDecimals as public). So we either should make it public everywhere or make it protected in the base class
    public ScaleDecimals(): number {
        return this.SymbolData.symbolInfo.decimals
    }

    public GetY(value: number): number {
        const MAX_RESULT_VALUE = 100000
        let result: number
        const paintRect = this.IsPaintContextCacheInitialized ? this.PaintContextCache.PaintRect : this.GetPaintRect()

        if (this.ChartOptions.FixedScale) {
            result = Math.round(
                (paintRect.Bottom + paintRect.Top) / 2 -
                    (value - this.ChartOptions.FixedScaleBaseLevel) * this.fVScale * this.ChartOptions.VerticalScale
            )
        } else {
            result = Math.round(paintRect.Bottom - this.vMargin - (value - this.fMinValue) * this.fVScale)
        }

        // Clamping the result to the range [-MAX_RESULT_VALUE, MAX_RESULT_VALUE] to prevent it from exceeding
        // the maximum value that is considered reasonable for the chart.
        if (Math.abs(result) > MAX_RESULT_VALUE) {
            result = Math.sign(result) * MAX_RESULT_VALUE
        }

        return result
    }

    private __Debug_PaintAdditionalInfo(dataAvailability: TDataAvailability): void {
        const canvasContext = this.PaintContextCache.canvasContext

        // Set font and color for drawing text
        canvasContext.font = '15px Roboto Flex'
        canvasContext.fillStyle = 'black'

        let currentLine_Y = 36 + 15
        const lineHeight = 15

        currentLine_Y += lineHeight

        if (!this.Bars.IsSeeked) {
            //FIXME: change this for loader
            canvasContext.fillText('Data is not seeked yet for this TF', 1, currentLine_Y)
        }

        currentLine_Y += lineHeight

        if (dataAvailability === TDataAvailability.da_MissingForThisDateRange) {
            //we are in a place where there cannot be any data for this currency pair
            //(like we are testing several currencies and data for one starts earlier than data for second)
            // canvasContext.fillText('No data for this date range', 1, currentLine_Y)
            return
        } else if (dataAvailability === TDataAvailability.da_NotSeekedYet) {
            // DebugUtils.log('Waiting for data to seek to last item in testing', this.Bars.DataDescriptor)
            // TODO LOADER + barinfo off
            // canvasContext.fillText('Waiting for data to seek to last item in testing', 1, currentLine_Y)
        }
        currentLine_Y += lineHeight
    }

    private __debug_PaintTickChunksInfo(): void {
        const visibleChunks = this.SymbolData.TickData.fTicks.GetChunksForRangeDates(
            this.PaintContextCache.firstVisibleDate,
            this.PaintContextCache.lastVisibleDate
        )
        this.__debug_DrawChunks(visibleChunks, TDataTypes.dt_Ticks)
    }

    private __debug_PaintBarChunksInfo(): void {
        const visibleChunks = this.Bars.GetChunksForRangeIndexes(
            this.PaintContextCache.firstVisibleIndex,
            this.PaintContextCache.lastVisibleIndex_excluding_emptyArea
        )
        this.__debug_DrawChunks(visibleChunks, TDataTypes.dt_Bars)
    }

    private __debug_DrawChunks(visibleChunks: TDownloadableChunk<TDataRecordWithDate>[], chunksType: TDataTypes): void {
        const canvasContext = this.PaintContextCache.canvasContext
        const paintRect = this.IsPaintContextCacheInitialized ? this.PaintContextCache.PaintRect : this.GetPaintRect()

        for (const chunk of visibleChunks) {
            const chunkStartX = Math.max(this.GetXFromDate(chunk.FirstDate), 0)
            const chunkEndX = Math.min(this.GetXFromDate(chunk.LastPossibleDate), paintRect.Right)
            const chunkWidth = chunkEndX - chunkStartX
            const chunkHeight = 15
            let chunkTextStarter: string
            if (chunksType === TDataTypes.dt_Bars) {
                chunkTextStarter = `bar chunk starting at: ${chunk.FirstGlobalIndex}`
            } else {
                chunkTextStarter = `tick chunk starting at: ${DateUtils.DF(chunk.FirstDate)}`
            }
            const chunkText = `${chunkTextStarter} status: ${chunk.Status}`

            // Set fill style based on chunk status
            switch (chunk.Status) {
                case TChunkStatus.cs_Empty: {
                    canvasContext.fillStyle = 'rgba(255, 0, 0, 0.2)'
                    break
                }
                case TChunkStatus.cs_InQueue:
                case TChunkStatus.cs_Building:
                case TChunkStatus.cs_PartiallyFilled: {
                    canvasContext.fillStyle = 'rgba(23, 2, 250, 1)'
                    break
                }
                case TChunkStatus.cs_Loaded: {
                    canvasContext.fillStyle = 'rgba(0, 128, 0, 0.2)'
                    break
                }
                case TChunkStatus.cs_InvalidDataOnServer: {
                    //purple
                    canvasContext.fillStyle = 'rgba(128, 0, 128, 0.2)'
                    break
                }
                default: {
                    throw new StrangeError('Invalid chunk.Status in DrawChunks')
                }
            }

            const startY = chunksType === TDataTypes.dt_Bars ? paintRect.Top : paintRect.Top + chunkHeight + 2

            // Draw the chunk
            canvasContext.fillRect(chunkStartX, startY, chunkWidth, chunkHeight)

            // Set text style
            canvasContext.fillStyle = 'black' // Text color
            canvasContext.font = '9px Roboto Flex' // Font style

            // Measure text width
            const textWidth = canvasContext.measureText(chunkText).width

            // Calculate text position
            const textX = Math.max(chunkStartX, paintRect.Left)
            const textY = startY + chunkHeight / 2 + 3

            // Draw the chunk number only if there's enough space
            if (chunkWidth > textWidth) {
                canvasContext.fillText(chunkText, textX, textY)
            }
        }
    }

    protected PaintHorizontalGrid(): void {
        const context = this.PaintContextCache.canvasContext

        context.strokeStyle = this.ChartOptions.ColorScheme.GridColor
        context.lineWidth = 0.4
        const R: TRect = this.GetPaintRect()
        let value: number = this.fMinGValue
        while (value < this.fMaxGValue) {
            const y: number = this.GetY(value)
            if (y > R.Top && y < R.Bottom) {
                context.beginPath()
                context.moveTo(Math.round(R.Left + 1), Math.round(y) + 0.5)
                context.lineTo(Math.round(R.Right - 1), Math.round(y) + 0.5)
                context.stroke()
            }
            value += this.fVPoint
        }
    }

    protected PaintBorder(): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Painting, 'Painting border')
        super.PaintBorder()
        let value: number

        const context = this.PaintContextCache.canvasContext
        const paintRect = this.IsPaintContextCacheInitialized ? this.PaintContextCache.PaintRect : this.GetPaintRect()

        // Paint grid price mark
        const PaintGridPriceMark = (valueInternal: number): void => {
            const y: number = this.GetY(valueInternal) // Define GetY appropriately
            if (y > paintRect.Top && y < paintRect.Bottom) {
                // price dots
                // context.beginPath()
                // context.moveTo(R.Right, y)
                // context.lineTo(R.Right + 2, y)
                // context.stroke()

                const textHeight = TGdiPlusCanvas.CalculateTextHeight(context, GlobalOptions.Options.VERTICAL_GRID_FONT)
                // Adjust text position and draw
                context.fillText(
                    this.SymbolData.FormatPriceToStr(valueInternal),
                    paintRect.Right + 4,
                    y + textHeight / 2
                )
            }
        }

        if (!this.CanBePainted) {
            return
        }

        context.strokeStyle = this.ChartOptions.ColorScheme.FrameAndTextColor
        context.fillStyle = this.ChartOptions.ColorScheme.FrameAndTextColor
        context.font = GlobalOptions.Options.VERTICAL_GRID_FONT

        value = this.fMinGValue
        while (value < this.fMaxGValue) {
            PaintGridPriceMark(value)
            value += this.fVPoint
        }

        // Reset fillStyle for background color
        context.fillStyle = this.ChartOptions.ColorScheme.BackgroundColor

        this.PaintIndicatorMarks()
        this.PaintHLinesMarkers()
        this.PaintBidAskMarkers()
        this.PaintOrderMarkers()
    }

    private PaintBidAskMarkers(): void {
        if (this.ChartOptions.ShowBidLevel) {
            this.PaintRightMarker(this.SymbolData.bid, this.determineColorOfPriceLevel(), true)
        }

        if (this.ChartOptions.ShowAskLevel) {
            this.PaintRightMarker(this.SymbolData.ask, this.ChartOptions.ColorScheme.AskColor, true)
        }
    }

    private PaintOrderMarkers(): void {
        const orders = GlobalProcessingCore.ProcessingCore.OpenPositions

        for (const order of orders) {
            order.paintRightMarkers(this)
        }
    }

    private PaintBidAskLevels(): void {
        const pricePenStyle = TPenStyle.psDash

        if (this.ChartOptions.ShowBidLevel) {
            this.PaintLevel(this.SymbolData.bid, pricePenStyle, this.determineColorOfPriceLevel())
        }

        if (this.ChartOptions.ShowAskLevel) {
            this.PaintLevel(this.SymbolData.ask, pricePenStyle, this.ChartOptions.ColorScheme.AskColor)
        }
    }

    private determineColorOfPriceLevel(): TColor {
        const goesUp = this.SymbolData.priceWentUp(this.ChartOptions.Timeframe)
        let resultColor: TColor | undefined

        if (goesUp) {
            if (
                this.ChartOptions.useCustomPriceGoesUpColor &&
                !this.ChartOptions.doNotUseCustomPriceGoesUpOrDownColor
            ) {
                resultColor = this.ChartOptions.ColorScheme.priceGoesUpColor
            } else {
                resultColor = this.ChartOptions.ColorScheme.CandleUpColor
            }
        } else {
            if (
                this.ChartOptions.useCustomPriceGoesDownColor &&
                !this.ChartOptions.doNotUseCustomPriceGoesUpOrDownColor
            ) {
                resultColor = this.ChartOptions.ColorScheme.priceGoesDownColor
            } else {
                resultColor = this.ChartOptions.ColorScheme.CandleDownColor
            }
        }

        return resultColor
    }

    //TODO: move this logic into layers files
    private showOutOfRangeBlockingLayer(): boolean {
        const veryFirstDateInHistory = GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(
            this.SymbolData.fTickData.VeryFirstDateInHistory
        )
        if (
            this.ChartWindow &&
            this.ChartWindow.getChartWindowLayers().isReady() &&
            veryFirstDateInHistory &&
            GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true) < veryFirstDateInHistory
        ) {
            const layerBlock = this.ChartWindow.getChartWindowLayers().getLayerBlockingChart()
            if (layerBlock) {
                const currentDate = DateUtils.DF(
                    GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTimeWithTimezoneAndDST()
                )
                if (!layerBlock.isActive || layerBlock.getState() !== EBlockingLayerState.goto) {
                    this.initGotoBlockingLayer(veryFirstDateInHistory, layerBlock, currentDate)
                } else {
                    layerBlock.setCurrentDate(currentDate)
                }
            }
            this.ChartWindow.getChartWindowLayers().getLayerChartControls().isActive = false
            return true
        }
        return false
    }

    //for cases when we scroll or seek to some random place in history and do not have any data there yet
    private showPreparingChartBlockingLayer() {
        if (this.isDataLoaded) {
            const firstIndex = this.position
            const lastIndex = this.GetLastVisibleBarIndex()

            const chunks = this.Bars.GetChunksForRangeIndexes(firstIndex, lastIndex)
            for (const chunk of chunks) {
                if (chunk.Status === TChunkStatus.cs_Loaded) {
                    return false
                }
            }
        }

        const layerBlock = this.ChartWindow.getChartWindowLayers().getLayerBlockingChart()
        if (layerBlock && (!layerBlock.isActive || layerBlock.getState() !== EBlockingLayerState.loader)) {
            layerBlock.switchStateTo(EBlockingLayerState.loader)
        }
        return true
    }

    private showBlockingLayer(): boolean {
        if (this.showOutOfRangeBlockingLayer()) {
            return true
        }
        if (this.showPreparingChartBlockingLayer()) {
            return true
        }
        return false
    }

    private initGotoBlockingLayer(veryFirstDateInHistory: number, layerBlock: LayerBlockingChart, currentDate: string) {
        let symbols: ServerSymbolInfo[] = []
        try {
            symbols = GlobalServerSymbolInfo.Instance.getSymbolsByDate(
                GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true)
            )
        } catch (error) {
            throw new StrangeError(`Error in getting symbols by date: ${error}`)
        }

        const firstDate = DateUtils.DF(veryFirstDateInHistory)
        layerBlock.setAvailableSymbolsAndDate(symbols, firstDate, currentDate)
        layerBlock.switchStateTo(EBlockingLayerState.goto)
    }

    private toggleGoToLastBarButton(startDate: TDateTime, endDate: TDateTime): void {
        if (this.ChartWindow && this.ChartWindow.getChartWindowLayers().isReady()) {
            const lastBar = this.GetLastBarInTesting()
            if (lastBar) {
                if (endDate < lastBar.DateTime) {
                    this.ChartWindow.getChartWindowLayers().showButtonMoveToLastBar()
                } else {
                    this.ChartWindow.getChartWindowLayers().hideButtonMoveToLastBar()
                }
            }
        }
    }

    private correctScrollPositionIfNecessary(): void {
        if (this.position > this.Bars.LastItemInTestingIndex) {
            this.ChartWindow.ScrollRight()
        }
    }

    public Paint(): void {
        try {
            if (!this.isDataLoaded) {
                return
            }

            DebugUtils.logTopic(ELoggingTopics.lt_Painting, `MainChart ${this.DName} paint started`)

            this.correctScrollPositionIfNecessary()

            this.PaintCounter++
            this.CalcDimensions()
            this.InitPaintContextCache()

            this.ClearBox()

            if (this.showBlockingLayer()) {
                return
            }

            const [startDate, endDate] = this.GetVisibleDateRangeAccordingToBars()

            this.toggleGoToLastBarButton(startDate, endDate)

            //TODO: move this to PaintContextCache and then reuse everywhere?
            this._currVisibleStartDate = startDate
            this._currVisibleEndDate = endDate
            DownloadController.Instance.onVisibleRangeChanged(startDate, endDate)

            this.Bars.PreloadDataIfNecessary(startDate, endDate)

            const dataAvailability = this.Bars.GetDataAvailability(startDate, endDate)

            // this.PaintAdditionalInfo(dataAvailability)

            this.ChartWindow.getChartWindowLayers().manageVisibilityControlsOnLayerByData(
                dataAvailability,
                this.ChartOptions
            )

            if (this.ChartWindow.getChartWindowLayers().getLayerBlockingChart()?.isActive) {
                return
            }

            //TODO: do we still need this here?
            switch (dataAvailability) {
                case TDataAvailability.da_NoMapYet:
                case TDataAvailability.da_NotSeekedYet: {
                    //info will be displayed in the PaintAdditionalInfo method for now, we can paint some additional info here later
                    return
                }
                case TDataAvailability.da_MissingForThisDateRange: {
                    return
                }

                case TDataAvailability.da_SomeAvailable:
                case TDataAvailability.da_AllAvailable: {
                    //we have enough data to continue
                    break
                }
                default: {
                    throw new StrangeError('Unsupported data availability')
                }
            }

            try {
                this.PaintIndicators()
                this.paintMarkerOnIndicator()
            } catch (error) {
                StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
            }

            if (this.ChartOptions.ShowGrid) {
                this.PaintGrid()
            }

            if (this.ChartOptions.ShowPeriodSeparators) {
                this.PaintPeriodSeparators()
            }

            if (this.ChartOptions.ShowVolume) {
                this.PaintVolume()
            }

            if (!this.IsProfitChart) {
                this.PaintBidAskLevels()
            }

            if (!this.ChartOptions.ChartOnForeground) {
                this.PaintBars()
            }

            if (this.ChartOptions.ShowNews) {
                this.PaintNews()
            }

            this.PaintTools.Paint()
            this.staticPaintTools.Paint()

            const priceTimeOrderMap = new Map<string, number>()
            if (GlobalOptions.Options.ShowCloseOrderMarker) {
                this.PaintHistory(priceTimeOrderMap)
            }
            if (GlobalOptions.Options.ShowOpenOrderMarker) {
                this.PaintOrderLevels(priceTimeOrderMap)
            }
            this.PaintTools.PaintOrderMarkers()

            if (this.ChartOptions.ChartOnForeground) {
                this.PaintBars()
            }

            if (this.isShowChunkDownloadLoading) {
                this.paintLoaderForDownloadableChunk()
            }

            // paint border and text on it

            this.PaintBorder()
            this.PaintRooler()
        } catch (error) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
        } finally {
            this.FreePaintContextCache()
            const chartWindow = this.ChartWindow

            if (chartWindow && !chartWindow.getChartWindowLayers().getLayerBlockingChart()?.isActive) {
                chartWindow.getChartWindowLayers()?.getManagerLayers()?.reDrawAll()
            }
        }
    }

    private getBorderColorByPriority(priority: string): string {
        switch (priority) {
            case '0': {
                return '#01B291'
            }
            case '1': {
                return '#F2B347'
            }
            case '2': {
                return '#FF4846'
            }
            default: {
                return '#000000'
            }
        }
    }

    private PaintNews() {
        const [startDate, endDate] = this.GetVisibleDateRangeAccordingToBars()

        const newsList = GlobalNewsController.Instance.getAllNewsByDateRange(startDate, endDate)
        this.shownNews = newsList

        GlobalNewsController.Instance.throttledUpdateNewsTab()

        const groupedNewsList = this.groupNewsByBar(newsList)
        GlobalNewsController.Instance.InfoAboutShownNews = {
            news: groupedNewsList,
            horizontalMagnifier: this.ChartOptions.HorzMagnifier
        }
        GlobalNewsController.Instance.SetBottomTopCoordinatesOfNews({
            bottom: this.GetPaintRect().Bottom,
            top: this.GetPaintRect().Bottom - 27 - 48
        })

        for (const group of groupedNewsList) {
            if (group.length > 1) {
                this.paintGroupedNewsItem(group)
            } else {
                this.paintNewsItem(group[0])
            }
        }
    }

    private groupNewsByBar(newsList: News[]): News[][] {
        const groupedNewsMap = new Map<number, News[]>()
        const xValueMap = new Map<number, number>()

        for (const newsItem of newsList) {
            const newsDate = DateUtils.fromUnixTimeSeconds(newsItem.Time)
            let x = xValueMap.get(newsItem.Time)

            if (x === undefined) {
                x = this.GetXFromDate(newsDate)
                xValueMap.set(newsItem.Time, x)
            }

            let closestX: number | null = null
            for (const key of groupedNewsMap.keys()) {
                if (Math.abs(key - x) < 24) {
                    closestX = key
                    break
                }
            }

            if (closestX === null) {
                groupedNewsMap.set(x, [newsItem])
            } else {
                groupedNewsMap.get(closestX)!.push(newsItem)
            }
        }

        return [...groupedNewsMap.values()]
    }

    private paintGroupedNewsItem(newsGroup: News[]) {
        const x = this.GetXFromDate(DateUtils.fromUnixTimeSeconds(newsGroup[0].Time)) - 12
        const y = this.GetPaintRect().Bottom - 27

        const countries = new Set(newsGroup.map((newsItem) => newsItem.Country))

        const processedCountries = new Set()
        let yShift = 0

        if (countries.size <= 2) {
            let itemThatIsSearched: News | null = null

            for (const newsItem of newsGroup) {
                const country = newsItem.Country

                // Skip if this country has already been processed
                if (processedCountries.has(country)) {
                    continue
                }

                // Mark this country as processed
                processedCountries.add(country)

                const newsCount = newsGroup.filter((item) => item.Country === country).length
                const img = CountryChartImages.getInstance().getChartCountryImageElement(country)

                for (const item of newsGroup) {
                    if (item.Country === country) {
                        item.setCoordinates(x + 12, y - yShift + 12, 10)
                        if (item.isSearched) {
                            itemThatIsSearched = item
                        }
                    }
                }

                if (img) {
                    this.drawImage(img, x, y - yShift)
                }

                this.drawNewsItemBorder(newsItem, x, y - yShift)

                if (itemThatIsSearched) {
                    itemThatIsSearched.paintLightBackground(this.PaintContextCache.canvasContext)
                }

                if (newsCount > 1) {
                    this.drawNewsGroupCount(newsCount, x, y - yShift)
                }

                yShift += 28
            }
        } else {
            const img = CountryChartImages.getInstance().getChartCountryImageElement('Entire World')

            if (img) {
                this.drawImage(img, x, y)
            }
            this.drawNewsItemBorder(newsGroup[0], x, y)
            for (const newsItem of newsGroup) {
                newsItem.setCoordinates(x + 12, y + 12, 10)
                if (newsItem.isSearched) {
                    newsItem.paintLightBackground(this.PaintContextCache.canvasContext)
                }
            }

            this.drawNewsGroupCount(newsGroup.length, x, y)
        }
    }

    private drawNewsGroupCount(count: number, x: number, y: number) {
        const context = this.PaintContextCache.canvasContext
        const radius = 8
        const centerX = x + 12 + radius
        const centerY = y + 12 - radius

        context.save()
        context.beginPath()
        context.arc(centerX, centerY, radius, 0, 2 * Math.PI)
        context.fillStyle = '#FF4846' // Red color for the circle
        context.fill()

        context.fillStyle = '#FFFFFF' // White color for the text
        if (count > 99) {
            context.font = '8px Roboto Flex'
        } else if (count > 9) {
            context.font = '10px Roboto Flex'
        } else {
            context.font = '10px Roboto Flex'
        }
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        context.fillText(count.toString(), centerX, centerY)
        context.restore()

        context.save()
        context.beginPath()
        context.ellipse(centerX, centerY, radius, radius, 0, 0, 2 * Math.PI)
        context.strokeStyle = '#ffffff'
        context.lineWidth = 1
        context.stroke()
        context.restore()
    }

    private paintNewsItem(newsItem: News) {
        const x = this.GetXFromDate(DateUtils.fromUnixTimeSeconds(newsItem.Time)) - 12
        const y = this.GetPaintRect().Bottom - 27
        const img = CountryChartImages.getInstance().getChartCountryImageElement(newsItem.Country)

        newsItem.setCoordinates(x + 12, y + 12, 10)
        if (img) {
            this.drawImage(img, x, y)
            if (newsItem.isSearched) {
                newsItem.paintLightBackground(this.PaintContextCache.canvasContext)
            }
            this.drawNewsItemBorder(newsItem, x, y)
        }
    }

    private drawImage(img: HTMLImageElement, x: number, y: number) {
        const context = this.PaintContextCache.canvasContext

        context.drawImage(img, x, y)
    }

    private drawNewsItemBorder(newsItem: News, x: number, y: number) {
        const borderColor = this.getBorderColorByPriority(newsItem.Priority)
        const borderWidth = newsItem.inFocusedState ? 2 : 1
        const centerX = x + 12
        const centerY = y + 12
        const radius = 12

        const context = this.PaintContextCache.canvasContext
        context.save()
        context.beginPath()
        context.ellipse(centerX, centerY, radius, radius, 0, 0, 2 * Math.PI)
        context.strokeStyle = borderColor
        context.lineWidth = borderWidth
        context.stroke()
        context.restore()
    }

    public getNewsUnderMouse(event: MouseEvent | TPoint): News[] {
        const point = this.MouseToLocal(event)

        const newsList = GlobalNewsController.Instance.InfoAboutShownNews?.news.flat()
        const newsUnderMouse: News[] = []

        if (!newsList) {
            return newsUnderMouse
        }

        // Check each news item to see if it is under the mouse pointer
        for (const newsItem of newsList) {
            newsItem.inFocusedState = false
            newsItem.isSearched = false
            if (newsItem.isInside(point.x, point.y)) {
                newsUnderMouse.push(newsItem)
                newsItem.inFocusedState = true
            }
        }

        return newsUnderMouse
    }

    public paintMarkerOnIndicator(): void {
        if (!TChart.SelIndValue) {
            return
        }

        if (
            TChart.SelIndValue.sender === this &&
            TChart.SelIndValue.runtimeIndicator !== null &&
            TChart.SelIndValue.runtimeIndicator.IsVisible()
        ) {
            TChart.SelIndValue.drawMarker(this.CanvasContext, this)
        }
    }

    // Helper function to paint order lines and text
    private PaintLine(
        tpos: TTradePosition,
        price: number,
        color: string,
        s: string,
        s1: string,
        PaintCircle: boolean
    ): void {
        const context = this.PaintContextCache.canvasContext
        const paintRect = this.PaintContextCache.PaintRect
        const textHeight: number = this.PaintContextCache.GdiCanvas.TextHeight('0') //TODO: this may be incorrect

        const y: number = this.GetY(price)
        if (y < paintRect.Top || y > paintRect.Bottom) return

        context.beginPath()
        context.moveTo(paintRect.Left, y)
        context.lineTo(paintRect.Right, y)
        context.strokeStyle = color
        context.setLineDash([5, 3]) // DashDot pattern
        context.stroke()
        context.setLineDash([]) // Reset to solid line

        context.fillStyle = this.ChartOptions.ColorScheme.FrameAndTextColor
        context.fillText(s, paintRect.Left + 2, y - textHeight)

        if (s1 !== '') {
            const textWidth = context.measureText(s1).width
            context.fillText(s1, paintRect.Right - 2 - textWidth, y - textHeight)
        }

        if (
            !PaintCircle ||
            (tpos.PosType !== TTradePositionType.tp_Buy && tpos.PosType !== TTradePositionType.tp_Sell)
        ) {
            return
        }

        const x: number = this.GetXFromDate(tpos.OpenTime)
        if (x < paintRect.Left - 4 || x > paintRect.Right + 4) return

        this.PaintHistoryMarker(x, y, textHeight, tpos.PosType)
    }

    protected PaintHistoryMarker(x: number, y: number, th: number, PosType: TTradePositionType): void {
        const context = this.PaintContextCache.canvasContext
        let y1: number
        let s: string

        // Setting the pen style to solid as per Delphi code, in canvas context, this translates to setting lineWidth.
        context.lineWidth = 1 // Assuming 1 is the default solid line width, adjust if necessary.

        switch (PosType) {
            case TTradePositionType.tp_Buy: {
                context.strokeStyle = this.ChartOptions.ColorScheme.BuyMarkerColor
                y1 = y + 4
                s = 'Buy'
                break
            }
            case TTradePositionType.tp_Sell: {
                context.strokeStyle = this.ChartOptions.ColorScheme.SellMarkerColor
                y1 = y - 2 - th
                s = 'Sell'
                break
            }
            default: {
                throw new StrangeError('Invalid PosType in PaintHistoryMarker')
            }
        }

        context.beginPath()
        context.ellipse(x, y, 5.5, 5.5, 0, 0, 2 * Math.PI)
        context.stroke()

        // paint Sell/Buy text
        context.fillStyle = context.strokeStyle
        context.fillText(s, x + 5, y1)
    }

    private doesCurrentPosHaveSelectedControl(pos: TTradePos): boolean {
        for (const control of this.ChartWindow.controlsManager.SelectedControls) {
            if (control instanceof OrderLevel && control.owner === pos) {
                return true
            }
        }
        return false
    }

    private someControlIsSelectedButNotForCurrentPos(pos: TTradePos): boolean {
        for (const control of this.ChartWindow.controlsManager.SelectedControls) {
            if (control instanceof OrderLevel && control.owner !== pos) {
                return true
            }
        }
        return false
    }

    protected PaintOrderLevels(priceTimeOrderMapInfo: Map<string, number>): void {
        const chartWindow = this.ChartWindow
        const controlsManager = chartWindow.controlsManager
        const hoveredControl: ChartControl | null = controlsManager.HoveredControl
        const selectedControls = controlsManager.SelectedControls

        for (let i = 0; i < GlobalProcessingCore.ProcessingCore.OpenPositions.length; i++) {
            const ttpos: TTradePos = GlobalProcessingCore.ProcessingCore.OpenPositions[i]
            const tpos = ttpos.tpos
            let yShiftForOrderIcon = 0

            if (tpos.SymbolName === this.SymbolData.symbolInfo.SymbolName) {
                const x = this.GetXFromDate(tpos.OpenTime)

                const mapKey = `${x}-${tpos.PosType}`

                if (priceTimeOrderMapInfo.has(mapKey)) {
                    yShiftForOrderIcon = priceTimeOrderMapInfo.get(mapKey)! + 1
                    priceTimeOrderMapInfo.set(mapKey, yShiftForOrderIcon)
                } else {
                    priceTimeOrderMapInfo.set(mapKey, yShiftForOrderIcon)
                }

                ttpos.setTransparentOrderLevels(false)

                if (this.doesCurrentPosHaveSelectedControl(ttpos)) {
                    ttpos.setTransparentOrderLevels(false)
                } else if (this.someControlIsSelectedButNotForCurrentPos(ttpos)) {
                    ttpos.setTransparentOrderLevels(true)
                } else if (selectedControls.length === 0 && !controlsManager.isModalOpened) {
                    ttpos.setTransparentOrderLevels(false)
                }

                if (hoveredControl && hoveredControl instanceof OrderLevel && hoveredControl.owner === ttpos) {
                    ttpos.setTransparentOrderLevels(false)
                } else if (hoveredControl && hoveredControl instanceof OrderLevel) {
                    ttpos.setTransparentOrderLevels(true)
                } else if (!hoveredControl && selectedControls.length === 0 && !controlsManager.isModalOpened) {
                    ttpos.setTransparentOrderLevels(false)
                }

                ttpos.paintOrderIcon(this.PaintContextCache.GdiCanvas, this, yShiftForOrderIcon)

                if (!this.doesCurrentPosHaveSelectedControl(ttpos) || !ttpos.isMyControl(hoveredControl)) {
                    ttpos.paintOrderLevel(this.PaintContextCache.GdiCanvas, this)
                }

                yShiftForOrderIcon = 0
            }
        }

        for (const selectedControl of selectedControls) {
            if (selectedControl && selectedControl instanceof OrderLevel) {
                const levels = selectedControl.owner.orderLevels.get(chartWindow)
                if (levels) {
                    levels.openPriceOrderLevel?.draw(this.PaintContextCache.GdiCanvas)
                    levels.stopLossOrderLevel?.draw(this.PaintContextCache.GdiCanvas)
                    levels.takeProfitOrderLevel?.draw(this.PaintContextCache.GdiCanvas)
                }
            }
        }

        if (hoveredControl && hoveredControl instanceof OrderLevel) {
            const levels = hoveredControl.owner.orderLevels.get(chartWindow)
            if (levels) {
                levels.openPriceOrderLevel?.draw(this.PaintContextCache.GdiCanvas)
                levels.stopLossOrderLevel?.draw(this.PaintContextCache.GdiCanvas)
                levels.takeProfitOrderLevel?.draw(this.PaintContextCache.GdiCanvas)
                const mousePos = this.getLocalMousePos()

                if (
                    levels.stopLossOrderLevel?.isMouseInside() &&
                    levels.stopLossOrderLevel?.isMouseInsideInfoBox(new TPoint(mousePos.x, mousePos.y)) &&
                    !levels.stopLossOrderLevel?.IsCaptured()
                ) {
                    levels.stopLossOrderLevel?.showTooltip(
                        this.PaintContextCache.GdiCanvas,
                        levels.stopLossOrderLevel?.infoBoxLocation
                    )
                }

                if (
                    levels.takeProfitOrderLevel?.isMouseInside() &&
                    levels.takeProfitOrderLevel?.isMouseInsideInfoBox(new TPoint(mousePos.x, mousePos.y)) &&
                    !levels.takeProfitOrderLevel?.IsCaptured()
                ) {
                    levels.takeProfitOrderLevel?.showTooltip(
                        this.PaintContextCache.GdiCanvas,
                        levels.takeProfitOrderLevel?.infoBoxLocation
                    )
                }
            }
        }
    }

    protected PaintHistory(priceTimeOrderMapInfo: Map<string, number>): void {
        const context = this.PaintContextCache.canvasContext
        context.strokeStyle = this.ChartOptions.ColorScheme.ProfitTransactionColor // Default color
        context.lineWidth = 1
        context.font = '12px Roboto Flex'
        let yShiftForOpenOrderIcon = 0
        let yShiftForCloseOrderIcon = 0

        // Fix for text height calculation
        const R: TRect = this.GetPaintRect()
        const [date1, date2] = this.GetVisibleDateRangeAccordingToBars()
        const hoveredControl = this.ChartWindow.controlsManager.HoveredControl
        const selectedControls = this.ChartWindow.controlsManager.SelectedControls

        for (const [_unused_i, historyItem] of GlobalProcessingCore.ProcessingCore.History.entries()) {
            if (
                historyItem.symbol?.symbolInfo.SymbolName === this.SymbolData.symbolInfo.SymbolName &&
                ![
                    TTradePositionType.tp_BuyStop,
                    TTradePositionType.tp_SellStop,
                    TTradePositionType.tp_BuyLimit,
                    TTradePositionType.tp_SellLimit,
                    TTradePositionType.tp_Cancelled
                ].includes(historyItem.tpos.PosType)
            ) {
                historyItem.hideClosedOrderInfoOutsideView()

                const tpos = historyItem.tpos
                const pt2 =
                    tpos.PosType === TTradePositionType.tp_Buy ? TTradePositionType.tp_Sell : TTradePositionType.tp_Buy
                // Filter by time

                const x1 = this.GetXFromDate(tpos.OpenTime)
                const x2 = this.GetXFromDate(tpos.CloseTime)
                if (x1 - 5 > R.Right || x2 + 5 < R.Left) {
                    continue
                }

                historyItem.showClosedOrderInfo()

                const mapKeyOpen = `${x1}-${tpos.PosType}`
                const mapKeyClose = `${x2}-${pt2}`

                if (priceTimeOrderMapInfo.has(mapKeyOpen)) {
                    yShiftForOpenOrderIcon = priceTimeOrderMapInfo.get(mapKeyOpen)! + 1
                    priceTimeOrderMapInfo = priceTimeOrderMapInfo.set(mapKeyOpen, yShiftForOpenOrderIcon)
                } else {
                    priceTimeOrderMapInfo.set(mapKeyOpen, yShiftForOpenOrderIcon)
                }

                if (priceTimeOrderMapInfo.has(mapKeyClose)) {
                    yShiftForCloseOrderIcon = priceTimeOrderMapInfo.get(mapKeyClose)! + 1
                    priceTimeOrderMapInfo = priceTimeOrderMapInfo.set(mapKeyClose, yShiftForCloseOrderIcon)
                } else {
                    priceTimeOrderMapInfo.set(mapKeyClose, yShiftForCloseOrderIcon)
                }

                historyItem.paintOrderIconForClosedOrder(
                    this.PaintContextCache.GdiCanvas,
                    this,
                    yShiftForOpenOrderIcon,
                    yShiftForCloseOrderIcon
                )

                yShiftForCloseOrderIcon = 0
                yShiftForOpenOrderIcon = 0

                historyItem.paintLinesForClosedOrder(this.PaintContextCache.GdiCanvas, this)
            }
        }

        for (const selectedControl of selectedControls) {
            if (selectedControl && selectedControl instanceof SellBuyConnectorLine) {
                selectedControl.drawInfoBoxes(this.PaintContextCache.GdiCanvas)
            }
        }

        if (hoveredControl && hoveredControl instanceof SellBuyConnectorLine) {
            hoveredControl.drawInfoBoxes(this.PaintContextCache.GdiCanvas)
        }
    }

    protected PaintPosMarker(): void {
        // Check if the position marker's DateTime is set to -1, which indicates it should not be painted.
        if (this.fPosMarker.DateTime === -1) return

        // Access the canvas context from a cached paint context.
        const context = this.PaintContextCache.canvasContext
        // Obtain the rectangle area where the painting should occur.
        const R: TRect = this.PaintContextCache.PaintRect
        // Calculate the X coordinate based on the DateTime of the position marker.
        const x: number = this.GetXFromDate(this.fPosMarker.DateTime)
        // Calculate the Y coordinate based on the price of the position marker.
        const y: number = this.GetY(this.fPosMarker.price)

        // Set the style for the pen to draw solid lines.
        context.strokeStyle = this.ChartOptions.ColorScheme.FrameAndTextColor
        context.lineWidth = 1

        // Draw a vertical line from the top of the paint rectangle to just above the marker.
        context.beginPath()
        context.moveTo(x, R.Top)
        context.lineTo(x, y - 7)
        context.stroke()

        // Draw an ellipse around the position marker.
        context.beginPath()
        context.arc(x, y, 7, 0, 2 * Math.PI)
        context.stroke()

        // Draw a vertical line from just below the marker to the bottom of the paint rectangle.
        context.beginPath()
        context.moveTo(x, y + 7)
        context.lineTo(x, R.Bottom)
        context.stroke()
    }

    private __debug_PaintChunksInfo(): void {
        this.__debug_PaintBarChunksInfo()
        this.__debug_PaintTickChunksInfo()
    }

    private PaintVolume(): void {
        if (!this.CanBePainted) return
        if (!this._bars) {
            DebugUtils.warn('No bar data available.')
            return
        }

        const context = this.PaintContextCache.canvasContext
        const R: TRect = this.PaintContextCache.PaintRect.Copy()
        let x: number = R.Left + (this.fLeftOffs ?? 0)
        const lastBarToPaintOnTheRight = Math.min(
            this.LeftPosition + this.PaintContextCache.NumberOfVisibleBars,
            this.Bars.LastItemInTestingIndex
        )

        const MaxVolume: number = this.Bars.GetMax(
            this.LeftPosition,
            this.LeftPosition + this.PaintContextCache.NumberOfVisibleBars,
            TSearchMode.sm_Volume
        )

        if (MaxVolume < 1) return

        const scale: number = 25 / MaxVolume
        for (let i: number = this.LeftPosition; i <= lastBarToPaintOnTheRight; i++) {
            const bar = this.Bars.GetItemByGlobalIndex(i)
            if (!bar) {
                // probably the bar was not downloaded yet, it is ok
                x += this.barSizeInPixels
                continue
            }

            // Determine the color of the volume bar based on the bar's trend
            let volumeColor: TColor
            if (bar.close >= bar.open) {
                volumeColor = `${this.ChartOptions.ColorScheme.CandleUpFillerColor}75`
            } else {
                volumeColor = `${this.ChartOptions.ColorScheme.CandleDownFillerColor}75`
            }

            context.strokeStyle = volumeColor
            context.fillStyle = volumeColor
            context.lineWidth = 1

            const volumeHeight: number = bar.volume * scale
            const y: number = R.Bottom - volumeHeight

            context.fillRect(x - this.fBarWidth2, y, this.fBarWidth2 * 2, volumeHeight)

            x += this.barSizeInPixels
        }
    }

    private getWidthFromRightToLastBar(): number {
        const lastBar = this.Bars.LastItemInTestingIndex
        const lastBarX = this.GetX(lastBar)
        const paintRect = this.GetPaintRect()
        return paintRect.Width - lastBarX
    }

    private getHeightFromBottomToTop(): number {
        const paintRect = this.GetPaintRect()
        return paintRect.Bottom - paintRect.Top
    }

    public getPriceBorderWidth(): number {
        return this.fMargins.Width
    }

    public getPriceBorderMargins(): TRect {
        return this.fMargins
    }

    private paintLoaderForDownloadableChunk() {
        this.visualLoader.drawLoaderForChunkDownloading(
            this.getWidthFromRightToLastBar(),
            this.getHeightFromBottomToTop(),
            this.getPriceBorderWidth(),
            this.GetX(this.Bars.LastItemInTestingIndex - 5)
        )
    }

    public startLoaderForDownloadableChunk(): void {
        this.isShowChunkDownloadLoading = true
        const { setSettings, settings } = chartSettingsStore
        setSettings({ ...settings, isSymbolLoading: true })
    }

    public stopLoaderForDownloadableChunk(): void {
        this.isShowChunkDownloadLoading = false
        const { setSettings, settings } = chartSettingsStore
        setSettings({ ...settings, isSymbolLoading: false })
        this.visualLoader.stopLoaderAnimation()
    }

    private PaintBar(x: number, y1: number, y2: number, R: TRect, c1: TColor, c2: TColor, c3: TColor): void {
        const context = this.PaintContextCache.canvasContext
        const dateX: number = Math.round(x)

        context.strokeStyle = c1
        context.fillStyle = c1
        context.setLineDash([])
        if (!GlobalOptions.Options.PaintThickerBars || R.Width < 5) {
            // Standard bar
            if (this.ChartOptions.HorzMagnifier > 3.5) {
                const rect: TRect = new TRect(
                    Math.round(dateX - 0.5),
                    y1,
                    Math.round(dateX + 0.5),
                    Math.abs(y2 - y1) <= 0 ? y1 + 1 : y2
                )
                this.GdiCanvas.brush = new IGPSolidBrush(c3)
                this.GdiCanvas.FillRect(rect)
                this.GdiCanvas.brush = new IGPSolidBrush(c1)
                const barBody: TRect = new TRect(
                    Math.round(dateX - R.Width / 2),
                    R.Top,
                    Math.round(dateX + R.Width / 2),
                    Math.abs(R.Bottom - R.Top) <= 0 ? R.Top + 1 : R.Bottom
                )
                if (barBody.IsValidForDrawing()) {
                    this.GdiCanvas.FillRect(barBody)
                }
                const innerBarBody: TRect = new TRect(
                    Math.round(barBody.Left + 1),
                    barBody.Top + 1,
                    Math.round(barBody.Right - 1),
                    barBody.Bottom - 1
                )
                if (innerBarBody.IsValidForDrawing()) {
                    this.GdiCanvas.brush = new IGPSolidBrush(c2)
                    this.GdiCanvas.FillRect(innerBarBody)
                }
            } else {
                if (Math.abs(y2 - y1) >= 1) {
                    this.GdiCanvas.brush = new IGPSolidBrush(c1)
                    context.beginPath()
                    context.moveTo(dateX + 0.5, y1)
                    context.lineTo(dateX + 0.5, y2)
                    context.stroke()
                } else {
                    const rect: TRect = new TRect(dateX - 1, y1, dateX + 1, Math.abs(y2 - y1) <= 0 ? y1 + 1 : y2)
                    if (rect.IsValidForDrawing()) {
                        this.GdiCanvas.brush = new IGPSolidBrush(c1)
                        this.GdiCanvas.FillRect(rect)
                    }
                }
            }
        } else {
            // Thicker bar
            context.beginPath()
            context.moveTo(x, y1)
            context.lineTo(x, y2)
            context.stroke()

            // Draw an additional line to make the bar look thicker
            context.beginPath()
            context.moveTo(x + 1, y1)
            context.lineTo(x + 1, y2)
            context.stroke()

            // Adjust the rectangle for thicker bar
            R.Right += 1

            if (Math.abs(R.Top - R.Bottom) > 2) {
                context.strokeRect(R.Left, R.Top, R.Width, R.Height)
                context.fillRect(R.Left + 1, R.Top + 1, R.Width - 2, R.Height - 2)

                // If conditions are met, fill the inner rectangle with a different color
                if (R.Height > 2 && R.Width > 1) {
                    context.fillStyle = c2
                    context.fillRect(R.Left + 2, R.Top + 2, R.Width - 4, R.Height - 4)
                }
            } else {
                context.beginPath()
                context.moveTo(R.Left, R.Top)
                context.lineTo(R.Right, R.Top)
                context.stroke()

                context.beginPath()
                context.moveTo(R.Left, R.Top + 1)
                context.lineTo(R.Right, R.Top + 1)
                context.stroke()
            }
        }
    }

    private PaintStick(x: number, y1: number, y2: number, R: TRect, c: string): void {
        const context = this.PaintContextCache.canvasContext

        context.strokeStyle = c

        // Set line dash to an empty array to ensure solid lines
        context.setLineDash([])

        const paintLine = (
            internal_x1: number,
            internal_y1: number,
            internal_x2: number,
            internal_y2: number,
            dx: number,
            dy: number
        ) => {
            context.beginPath()
            context.moveTo(internal_x1, internal_y1)
            context.lineTo(internal_x2, internal_y2)
            context.stroke()

            if (dx !== 0 || dy !== 0) {
                context.beginPath()
                context.moveTo(internal_x1 + dx, internal_y1 + dy)
                context.lineTo(internal_x2 + dx, internal_y2 + dy)
                context.stroke()
            }
        }

        if (GlobalOptions.Options.PaintThickerBars) {
            paintLine(x, y1, x, y2, 1, 0)
        } else {
            paintLine(x, y1, x, y2, 0, 0)
        }

        if (y1 <= y2) {
            if (GlobalOptions.Options.PaintThickerBars) {
                paintLine(x, R.Top, R.Left - 1, R.Top, 0, 1)
                paintLine(x, R.Bottom, R.Right + 1, R.Bottom, 0, -1)
            } else {
                paintLine(x, R.Top, R.Left - 1, R.Top, 0, 0)
                paintLine(x, R.Bottom, R.Right, R.Bottom, 0, 0)
            }
        } else {
            if (GlobalOptions.Options.PaintThickerBars) {
                paintLine(x, R.Bottom, R.Left - 1, R.Bottom, 0, -1)
                paintLine(x, R.Top, R.Right + 1, R.Top, 0, 1)
            } else {
                paintLine(x, R.Bottom, R.Left - 1, R.Bottom, 0, 0)
                paintLine(x, R.Top, R.Right, R.Top, 0, 0)
            }
        }
    }

    private PaintBars(): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Painting, 'Painting bars')

        if (!this.Bars) {
            DebugUtils.error('No bar data available.')
            return
        }

        const context = this.PaintContextCache.canvasContext

        const R: TRect = this.PaintContextCache.PaintRect.Copy()
        let x: number = R.Left + (this.fLeftOffs ?? 0)
        const lastBarToPaintOnTheRight = Math.min(
            this.LeftPosition + this.PaintContextCache.NumberOfVisibleBars,
            this.Bars.LastItemInTestingIndex
        )
        let previousCloseY: number | null = null

        for (let i: number = this.LeftPosition; i <= lastBarToPaintOnTheRight; i++) {
            const bar = this.Bars.GetItemByGlobalIndex(i)
            if (!bar) {
                // probably the bar was not downloaded yet, it is ok
                x += this.barSizeInPixels
                continue
            }

            if (this.ChartOptions.ChartStyle === TChartStyle.cs_Line) {
                const currentCloseY = this.GetY(bar.close)
                if (previousCloseY !== null) {
                    context.beginPath()
                    context.moveTo(x - this.barSizeInPixels, previousCloseY) // Start from the previous close
                    context.lineTo(x, currentCloseY) // Draw to the current close
                    context.stroke()
                }
                previousCloseY = currentCloseY
            } else {
                const barOpen = bar.open
                const barHigh = bar.high
                const barLow = bar.low
                const barClose = bar.close

                const open_in_Y: number = this.GetY(barOpen)
                let high_in_Y: number = this.GetY(barHigh)
                let low_in_Y: number = this.GetY(barLow)
                const close_in_Y: number = this.GetY(barClose)

                let c1: TColor, c2: TColor, c3: TColor

                if (barClose >= barOpen) {
                    R.DoSetRect(x - this.fBarWidth2, close_in_Y, x + this.fBarWidth2 + 1, open_in_Y + 1)
                    c1 = this.ChartOptions.ColorScheme.CandleUpColor
                    c2 = this.ChartOptions.ColorScheme.CandleUpFillerColor
                    c3 = this.ChartOptions.ColorScheme.CandleUpShadowColor
                } else {
                    R.DoSetRect(x - this.fBarWidth2, open_in_Y, x + this.fBarWidth2 + 1, close_in_Y + 1)
                    c1 = this.ChartOptions.ColorScheme.CandleDownColor
                    c2 = this.ChartOptions.ColorScheme.CandleDownFillerColor
                    c3 = this.ChartOptions.ColorScheme.CandleDownShadowColor
                    ;[low_in_Y, high_in_Y] = [high_in_Y, low_in_Y]
                }

                switch (this.ChartOptions.ChartStyle) {
                    case TChartStyle.cs_Bar: {
                        this.PaintBar(x, low_in_Y, high_in_Y, R, c1, c2, c3)
                        break
                    }
                    case TChartStyle.cs_Stick: {
                        this.PaintStick(x, low_in_Y, high_in_Y, R, c1)
                        break
                    }
                    default: {
                        throw new StrangeError('We should not be here for this chart style')
                    }
                }
            }

            x += this.barSizeInPixels
        }
    }

    public getLocalMousePos(): { x: number; y: number } {
        return this.MouseToLocal()
    }

    public getPreciseDateUnderMouse(): TDateTime {
        const localMouseCoords = this.GetCurrentMousePositionInLocalCoordinates()

        return this.GetPreciseDateFromX(localMouseCoords.x)
    }
}
