import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TRect } from '../../../delphi_compatibility/DelphiBasicTypes'
import { TLineStyleRec } from '../../../drawing_interface/GraphicObjects'

export type TDateTime = number

export default class Constants {
    static readonly MAX_INT = 2147483647
}

export class TOptValue {}

export class TOptValue_str extends TOptValue {
    value: string

    constructor(value: string) {
        super()
        this.value = value
    }
}

export class TOptValue_number extends TOptValue {
    protected fValue: number

    constructor(value: number) {
        super()
        this.fValue = value
    }

    public get value(): number {
        return this.fValue
    }

    public set value(value: number) {
        this.fValue = value
    }
}
export enum TimeValue {
    '00:00' = '00:00',
    '00:15' = '00:15',
    '00:30' = '00:30',
    '00:45' = '00:45',
    '01:00' = '01:00',
    '01:15' = '01:15',
    '01:30' = '01:30',
    '01:45' = '01:45',
    '02:00' = '02:00',
    '02:15' = '02:15',
    '02:30' = '02:30',
    '02:45' = '02:45',
    '03:00' = '03:00',
    '03:15' = '03:15',
    '03:30' = '03:30',
    '03:45' = '03:45',
    '04:00' = '04:00',
    '04:15' = '04:15',
    '04:30' = '04:30',
    '04:45' = '04:45',
    '05:00' = '05:00',
    '05:15' = '05:15',
    '05:30' = '05:30',
    '05:45' = '05:45',
    '06:00' = '06:00',
    '06:15' = '06:15',
    '06:30' = '06:30',
    '06:45' = '06:45',
    '07:00' = '07:00',
    '07:15' = '07:15',
    '07:30' = '07:30',
    '07:45' = '07:45',
    '08:00' = '08:00',
    '08:15' = '08:15',
    '08:30' = '08:30',
    '08:45' = '08:45',
    '09:00' = '09:00',
    '09:15' = '09:15',
    '09:30' = '09:30',
    '09:45' = '09:45',
    '10:00' = '10:00',
    '10:15' = '10:15',
    '10:30' = '10:30',
    '10:45' = '10:45',
    '11:00' = '11:00',
    '11:15' = '11:15',
    '11:30' = '11:30',
    '11:45' = '11:45',
    '12:00' = '12:00',
    '12:15' = '12:15',
    '12:30' = '12:30',
    '12:45' = '12:45',
    '13:00' = '13:00',
    '13:15' = '13:15',
    '13:30' = '13:30',
    '13:45' = '13:45',
    '14:00' = '14:00',
    '14:15' = '14:15',
    '14:30' = '14:30',
    '14:45' = '14:45',
    '15:00' = '15:00',
    '15:15' = '15:15',
    '15:30' = '15:30',
    '15:45' = '15:45',
    '16:00' = '16:00',
    '16:15' = '16:15',
    '16:30' = '16:30',
    '16:45' = '16:45',
    '17:00' = '17:00',
    '17:15' = '17:15',
    '17:30' = '17:30',
    '17:45' = '17:45',
    '18:00' = '18:00',
    '18:15' = '18:15',
    '18:30' = '18:30',
    '18:45' = '18:45',
    '19:00' = '19:00',
    '19:15' = '19:15',
    '19:30' = '19:30',
    '19:45' = '19:45',
    '20:00' = '20:00',
    '20:15' = '20:15',
    '20:30' = '20:30',
    '20:45' = '20:45',
    '21:00' = '21:00',
    '21:15' = '21:15',
    '21:30' = '21:30',
    '21:45' = '21:45',
    '22:00' = '22:00',
    '22:15' = '22:15',
    '22:30' = '22:30',
    '22:45' = '22:45',
    '23:00' = '23:00',
    '23:15' = '23:15',
    '23:30' = '23:30',
    '23:45' = '23:45',
    '24:00' = '24:00'
}

export class TimeValues {
    public static readonly TIMES: { value: TimeValue; label: string; timeInSeconds: number }[] = []

    static {
        for (const key of Object.keys(TimeValue)) {
            const value = TimeValue[key as keyof typeof TimeValue]
            TimeValues.TIMES.push({
                value: value,
                label: value,
                timeInSeconds: TimeValues.timeToSeconds(value)
            })
        }
    }

    constructor() {}

    private static timeToSeconds(time: TimeValue): number {
        const [hours, minutes] = time.split(':').map(Number)
        return hours * 3600 + minutes * 60
    }

    public static isValidTime(value: string): value is TimeValue {
        return TimeValues.TIMES.some((time) => time.value === value)
    }

    public static getTimeInSeconds(value: TimeValue): number {
        const timeEntry = TimeValues.TIMES.find((entry) => entry.value === value)
        if (timeEntry) {
            return timeEntry.timeInSeconds
        } else {
            throw new StrangeError('Invalid time value')
        }
    }
}

export class TOptValue_Session extends TOptValue {
    isEnabled: boolean
    name: string
    startTime: TimeValue
    endTime: TimeValue
    color: string

    constructor(isEnable: boolean, name: string, startTime: TimeValue, endTime: TimeValue, color: string) {
        super()
        this.isEnabled = isEnable
        this.name = name
        this.startTime = startTime
        this.endTime = endTime
        this.color = color
    }

    toString(): string {
        return `${this.isEnabled},${this.name},${this.startTime},${this.endTime},${this.color}`
    }

    static copyFromString(str: string): TOptValue_Session {
        const arr = str.split(',')
        if (arr.length !== 5) {
            throw new StrangeError('Invalid string format')
        }
        if (!TimeValues.isValidTime(arr[2]) || !TimeValues.isValidTime(arr[3])) {
            throw new StrangeError('Invalid time format')
        }

        return new TOptValue_Session(arr[0] === 'true', arr[1], arr[2] as TimeValue, arr[3] as TimeValue, arr[4])
    }

    static updateFromString(str: string, opt: TOptValue_Session): void {
        const arr = str.split(',')
        if (arr.length !== 5) {
            throw new StrangeError('Invalid string format')
        }
        if (!TimeValues.isValidTime(arr[2]) || !TimeValues.isValidTime(arr[3])) {
            throw new StrangeError('Invalid time format')
        }

        opt.isEnabled = arr[0] === 'true'
        opt.name = arr[1]
        opt.startTime = arr[2] as TimeValue
        opt.endTime = arr[3] as TimeValue
        opt.color = arr[4]
    }
}

export class TOptValue_SessionsArray extends TOptValue {
    sessions: TOptValue_Session[]

    constructor(sessions: TOptValue_Session[]) {
        super()
        this.sessions = sessions
    }

    addSession(session: TOptValue_Session): void {
        this.sessions.push(session)
    }

    getSessions(): TOptValue_Session[] {
        return this.sessions
    }

    toString(): string {
        return this.sessions.map((session) => session.toString()).join(';')
    }

    static copyFromString(str: string): TOptValue_SessionsArray {
        try {
            if (str.length === 0) {
                return new TOptValue_SessionsArray([])
            }
            const sessions = str.split(';').map((sessionStr) => TOptValue_Session.copyFromString(sessionStr))
            return new TOptValue_SessionsArray(sessions)
        } catch {
            throw new Error('Invalid string format')
        }
    }

    static updateFromString(str: string, opt: TOptValue_SessionsArray): void {
        try {
            if (str.length === 0) {
                opt.sessions = []
                return
            }
            const sessions = str.split(';').map((sessionStr) => TOptValue_Session.copyFromString(sessionStr))
            opt.sessions = sessions
        } catch {
            throw new Error('Invalid string format')
        }
    }
}

export class TOptValue_bool extends TOptValue {
    value: boolean

    constructor(value: boolean) {
        super()
        this.value = value
    }
}

export class TOptValue_LineStyle extends TOptValue {
    value: TLineStyleRec

    constructor(value: TLineStyleRec) {
        super()
        this.value = value
    }
}

export class TOptValue_HotKey extends TOptValue {
    value: number

    constructor(value: number) {
        super()
        this.value = value
    }
}

export class TOptValue_DateTime extends TOptValue {
    value: TDateTime

    constructor(value: TDateTime) {
        super()
        this.value = value
    }
}

export enum MacdMode {
    MAIN,
    SIGNAL
}

export enum TPriceType {
    pt_Close,
    pt_Open,
    pt_High,
    pt_Low,
    pt_HL2,
    pt_HLC3,
    pt_HLCC4
}

export enum TMAType {
    ma_SMA,
    ma_EMA,
    ma_SSMA,
    ma_WMA
}

export enum TValueType {
    vt_Open,
    vt_High,
    vt_Low,
    vt_Close,
    vt_Volume
}

export class TSymbolInfo_Indicators {
    Symbol!: string // symbol name
    Digits!: number // number of digits after '.'
    spread!: number // spread in pips
    Point!: number // minimal symbol point
    lot!: number // 1 lot cost
    curr!: string // lot currency
    SwapLong!: number // swap points long
    SwapShort!: number // swap points short
}

export class TIndicatorOption {
    ParamsStr!: string // params string
    BufferPtr!: any // pointer to buffer object
}

export enum TPenStyle {
    psSolid = 0,
    psDash = 1,
    psDot = 2,
    psDashDot = 3,
    psDashDotDot = 4,
    psClear = 5,
    psInsideFrame = 6,
    psUserStyle = 7,
    psAlternate = 8
}

export enum TOptionType {
    ot_Longword,
    ot_Integer,
    ot_double,
    ot_String,
    ot_Boolean,
    ot_EnumType,
    ot_Timeframe,
    ot_Currency,
    ot_LineStyle,
    ot_Separator,
    ot_Indicator,
    ot_Color,
    ot_DateTime,
    at_Levels,
    ot_Session,
    ot_SessionsArray
}

export enum TOutputWindow {
    ow_ChartWindow,
    ow_SeparateWindow
}

export enum TDrawStyle {
    ds_Line,
    ds_Histogram,
    ds_Fill,
    ds_Symbol,
    ds_Section,
    ds_HistogramFill,
    ds_ColoredCandles,
    ds_None
}

export enum TObjectType {
    obj_AnyObject,
    obj_VLine,
    obj_HLine,
    obj_TrendLine,
    obj_Ray,
    obj_Polyline,
    obj_FiboRetracement,
    obj_FiboTimeZones,
    obj_FiboArc,
    obj_FiboFan,
    obj_AndrewsPitchfork,
    obj_Text,
    obj_TextLabel,
    obj_Rectangle,
    obj_Ellipse,
    obj_Triangle,
    obj_FiboChannel,
    obj_LRChannel,
    obj_FiboExtension,
    obj_GannBox
}

export class TChartInfo {
    FirstIndex!: number // index of left bar on the chart
    LastIndex!: number // index or right bar on the chart
    PaintRect!: TRect // paint rect
    BarWidth!: number // width of bar in pixels
    currZoom!: number // current zoom
}

export const cl_GridColor = 0x01000000

export const PERIOD_M1 = 1
export const PERIOD_M5 = 5
export const PERIOD_M15 = 15
export const PERIOD_M30 = 30
export const PERIOD_H1 = 60
export const PERIOD_H4 = 60 * 4
export const PERIOD_D1 = 60 * 24
export const PERIOD_W1 = 60 * 24 * 7
export const PERIOD_MN1 = 60 * 24 * 30

export const tlTop = 0
export const tlCenter = 1
export const tlBottom = 2

export const taLeftJustify = 0
export const taRightJustify = 1
export const taCenter = 2

export const MODE_OPEN = 0
export const MODE_LOW = 1
export const MODE_HIGH = 2
export const MODE_CLOSE = 3
export const MODE_VOLUME = 4
export const MODE_TIME = 5

export const CHARTEVENT_KEYDOWN = 0
export const CHARTEVENT_OBJECT_CLICK = 1
export const CHARTEVENT_OBJECT_DRAG = 2
export const CHARTEVENT_CLICK = 4
export const CHARTEVENT_OBJECT_DELETE = 6
export const CHARTEVENT_OBJECT_CREATE = 7
export const CHARTEVENT_OBJECT_CHANGE = 8
export const CHARTEVENT_MOUSE_MOVE = 10

export class IndicatorInterfaceUnit {}
