export const apiURL = import.meta.env.VITE_SERVER_URL

export const GOOGLE_LOGIN_URL = `${apiURL}identity/Auth/googlev2`
export const EMAIL_LOGIN_URL = `${apiURL}identity/api/Account/SignIn`
export const EMAIL_REGISTER_URL = `${apiURL}identity/api/Account/SignUp`
export const REQUEST_CODE_URL = `${apiURL}identity/api/code`
export const CHANGE_EMAIL_URL = `${apiURL}identity/api/Account/email`
export const CHANGE_PASSWORD_URL = `${apiURL}identity/api/Account/password`
export const FORGOT_PASSWORD_URL = `${apiURL}identity/api/Account/forgotpassword`
export const MICROSOFT_LOGIN_URL = `${apiURL}identity/Auth/microsoft`
export const REFRESH_TOKEN_URL = `${apiURL}identity/api/Account/token`
export const RENEW_SUBSCRIPTION_URL = `${apiURL}identity/api/payment/renew`
export const SUSPEND_SUBSCRIPTION_URL = `${apiURL}identity/api/payment/suspend`
export const ACTIVE_SUBSCRIPTION_INFO_URL = `${apiURL}identity/v1/Subscriptions/Active`

export const PROJECT_BY_ID_URL = `${apiURL}projects/api/Projects`
export const FETCH_HISTORICAL_DATA_BARS = `${apiURL}data/api/HistoricalData/bars/raw`
export const SYMBOL_FULL_INFO = `${apiURL}data/api/HistoricalDataQuality/SymbolInfo/`
export const ALL_SYMBOLS_URL = `${apiURL}data/api/HistoricalDataQuality/symbols/`

export const TELEMETRY_URL = `${apiURL}telemetry/api/userEvent`

export const GOOGLE_OAUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${import.meta.env.VITE_GOOGLE_APP_ID}&response_type=token id_token&scope=openid email profile&prompt=select_account`

export const CHART_TEMPLATE_URL = `${apiURL}templates/charts`
export const INDICATOR_TEMPLATE_URL = `${apiURL}templates/indicators`
export const TOOL_TEMPLATE_URL = `${apiURL}templates/tools`
export const ALL_TEMPLATES_URL = `${apiURL}templates/all`
