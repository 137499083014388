import { BasicPaintToolJSON } from '@fto/lib/ProjectAdapter/Types'
import { DateUtils, TDateTime } from '../../../delphi_compatibility/DateUtils'
import { TPoint } from '../../../delphi_compatibility/DelphiBasicTypes'
import { DelphiMathCompatibility } from '../../../delphi_compatibility/DelphiMathCompatibility'
import { TChart } from '../../chart_classes/BasicChart'
import { TBasicPaintTool } from '../BasicPaintTool'
import { TCoordsRect, TPaintToolStatus, TPaintToolType, TPointInfo, TPointsArray } from '../PaintToolsAuxiliaryClasses'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TNoExactMatchBehavior } from '@fto/lib/ft_types/data/chunks/ChunkEnums'

export class TRectPaintTool extends TBasicPaintTool {
    constructor(aChart: TChart, aShortName: string) {
        super(aChart, aShortName)
        this.fToolType = TPaintToolType.tt_Polygon
        this.fMaxPoints = 2
    }

    clone(): TRectPaintTool {
        const cloneObj = new TRectPaintTool(this.fChart, this.ShortName)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        return cloneObj
    }

    public toJson(): BasicPaintToolJSON {
        return super.toJson()
    }

    public fromJSON(json: BasicPaintToolJSON) {
        super.fromJSON(json)
    }

    private LoopIndex(index: number, max: number): number {
        return DelphiMathCompatibility.Mod(index, max)
    }

    public OnMovePoint(index: number, time: TDateTime, price: number): void {
        super.OnMovePoint(index, time, price)

        const idx1: number = this.LoopIndex(index - 1, 4)
        const idx2: number = this.LoopIndex(index + 1, 4)

        if (index === 1 || index === 3) {
            this.fPoints[idx1].price = price
            this.fPoints[idx2].time = time
        } else if (index === 0 || index === 2) {
            this.fPoints[idx1].time = time
            this.fPoints[idx2].price = price
        }

        switch (index) {
            case 4: {
                this.fPoints[0].price = price
                this.fPoints[1].price = price

                break
            }
            case 5: {
                this.fPoints[1].time = time
                this.fPoints[2].time = time

                break
            }
            case 6: {
                this.fPoints[2].price = price
                this.fPoints[3].price = price

                break
            }
            case 7: {
                this.fPoints[3].time = time
                this.fPoints[0].time = time

                break
            }
            // No default
        }

        this.adjustMidpoints()
    }

    AdjustExtendPoints(): void {
        const canvasRightEdge = this.fChart.GdiCanvas.graphics.Context.canvas.width
        const canvasLeftEdge = 0

        const rightCanvasEdgePointTop = new TPointInfo(canvasRightEdge, this.fPoints[1].y)
        rightCanvasEdgePointTop.price = this.fPoints[1].price

        const leftCanvasEdgePointBottom = new TPointInfo(canvasLeftEdge, this.fPoints[2].y)
        leftCanvasEdgePointBottom.price = this.fPoints[2].price

        const leftCanvasEdgePointTop = new TPointInfo(canvasLeftEdge, this.fPoints[0].y)
        leftCanvasEdgePointTop.price = this.fPoints[0].price

        const rightCanvasEdgePointBottom = new TPointInfo(canvasRightEdge, this.fPoints[3].y)
        rightCanvasEdgePointBottom.price = this.fPoints[3].price

        if (this.fPoints[0].x > this.fPoints[1].x) {
            this.fExtendsRay.leftfPoints = [
                { 1: [this.fPoints[0], this.fPoints[1], null] },
                { 0: [this.fPoints[3], this.fPoints[2], null] }
            ]
            this.fExtendsRay.rightfPoints = [
                { 0: [this.fPoints[1], this.fPoints[0], null] },
                { 1: [this.fPoints[2], this.fPoints[3], null] }
            ]

            this.fExtendsRay.fillLeftfPoints = [{ 0: [this.fPoints[2], this.fPoints[1], null] }]
            this.fExtendsRay.fillRightfPoints = [{ 0: [this.fPoints[0], this.fPoints[3], null] }]
        } else {
            this.fExtendsRay.leftfPoints = [
                { 0: [this.fPoints[1], leftCanvasEdgePointTop, null] },
                { 1: [this.fPoints[2], leftCanvasEdgePointBottom, null] }
            ]
            this.fExtendsRay.rightfPoints = [
                { 0: [this.fPoints[3], rightCanvasEdgePointBottom, null] },
                { 1: [this.fPoints[0], rightCanvasEdgePointTop, null] }
            ]
            this.fExtendsRay.fillLeftfPoints = [{ 0: [this.fPoints[0], this.fPoints[3], null] }]
            this.fExtendsRay.fillRightfPoints = [{ 0: [this.fPoints[2], this.fPoints[1], null] }]
        }
    }

    private adjustMidpoints(): void {
        const midTop = this.fPoints[4]
        const midRight = this.fPoints[5]
        const midBottom = this.fPoints[6]
        const midLeft = this.fPoints[7]

        const index0 = this.chart.GetGlobalIndexByDate(
            this.fPoints[0].time,
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            true
        )
        const index1 = this.chart.GetGlobalIndexByDate(
            this.fPoints[1].time,
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            true
        )

        if (index1 - index0 >= 1 || index1 - index0 <= 1) {
            const midTopIndex = (index0 + index1) / 2

            if (midTopIndex % 2 === 0) {
                midTop.time = this.fChart.GetDateByIndex(midTopIndex)
            } else {
                const lowerIndex = Math.floor(midTopIndex)
                const upperIndex = Math.ceil(midTopIndex)

                let lowerTime = this.fChart.GetDateByIndex(lowerIndex)
                const upperTime = this.fChart.GetDateByIndex(upperIndex)

                const diffInDays = DateUtils.AbsDiffInDays(lowerTime, upperTime)

                if (diffInDays > 1) {
                    lowerTime = DateUtils.IncDay(lowerTime, Math.floor(diffInDays))
                }

                const timeBetweenIndexes = (upperTime - lowerTime) / 2

                midTop.time = lowerTime + timeBetweenIndexes
            }
        }
        midTop.price = this.fPoints[0].price

        midRight.time = this.fPoints[1].time
        midRight.price = (this.fPoints[1].price + this.fPoints[2].price) / 2

        const index2 = this.chart.GetGlobalIndexByDate(
            this.fPoints[2].time,
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            true
        )

        const index3 = this.chart.GetGlobalIndexByDate(
            this.fPoints[3].time,
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            true
        )

        if (index3 - index2 >= 1 || index3 - index2 <= 1) {
            const midBottomIndex = (index2 + index3) / 2

            if (midBottomIndex % 2 === 0) {
                midBottom.time = this.fChart.GetDateByIndex(midBottomIndex)
            } else {
                const lowerIndex = Math.floor(midBottomIndex)
                const upperIndex = Math.ceil(midBottomIndex)

                let lowerTime = this.fChart.GetDateByIndex(lowerIndex)
                const upperTime = this.fChart.GetDateByIndex(upperIndex)

                const diffInDays = DateUtils.AbsDiffInDays(lowerTime, upperTime)

                if (diffInDays > 1) {
                    lowerTime = DateUtils.IncDay(lowerTime, Math.floor(diffInDays))
                }

                const timeBetweenIndexes = (upperTime - lowerTime) / 2

                midBottom.time = lowerTime + timeBetweenIndexes
            }
        }
        midBottom.price = this.fPoints[2].price

        midLeft.time = this.fPoints[0].time
        midLeft.price = (this.fPoints[0].price + this.fPoints[3].price) / 2
    }

    private addPoint(x: number, y: number): void {
        const point = new TPointInfo(x, y)
        point.time = x
        point.price = y
        this.fPoints.add(point)
    }

    protected AdjustRectangle(): void {
        const R: TCoordsRect = this.GetCoordsRect()
        this.fPoints.clear()

        this.addPoint(R.x1, R.y1)
        this.addPoint(R.x2, R.y1)
        this.addPoint(R.x2, R.y2)
        this.addPoint(R.x1, R.y2)

        // Midpoints
        this.addPoint((R.x1 + R.x2) / 2, R.y1) // Top middle
        this.addPoint(R.x2, (R.y1 + R.y2) / 2) // Right middle
        this.addPoint((R.x1 + R.x2) / 2, R.y2) // Bottom middle
        this.addPoint(R.x1, (R.y1 + R.y2) / 2) // Left middle
    }

    public getPoints(): TPointsArray {
        // Calculate the minimum and maximum x and y values
        const x1 = Math.min(this.fPoints[0].x, this.fPoints[1].x)
        const x2 = Math.max(this.fPoints[0].x, this.fPoints[1].x)
        const y1 = Math.min(this.fPoints[0].y, this.fPoints[1].y)
        const y2 = Math.max(this.fPoints[0].y, this.fPoints[1].y)

        // Create the result array with the points defining the rectangle
        const result: TPointsArray = [new TPoint(x1, y1), new TPoint(x2, y1), new TPoint(x2, y2), new TPoint(x1, y2)]

        return result
    }

    public OnComplete(): void {
        this.fMaxPoints = 8
        this.AdjustRectangle()
        this.adjustMidpoints()
    }

    public RecountScreenCoords(): void {
        // Check if there are exactly two points and the status is completed, then adjust the rectangle
        if (this.fPoints.Count === 2 && this.fStatus === TPaintToolStatus.ts_Completed) {
            this.AdjustRectangle()
        }

        // Call the inherited RecountScreenCoords to ensure any base class logic is executed
        super.RecountScreenCoords()
    }

    protected PaintLines(): void {
        if (this.Points.length < 2) {
            return
        }

        const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas
        this.fLineStyle.getPen().applyDashPattern(gdiPlusCanvas.graphics.Context)

        gdiPlusCanvas.MoveTo(this.Points[0].x, this.Points[0].y)
        for (let i = 1; i < 4; i++) {
            gdiPlusCanvas.LineTo(this.Points[i].x, this.Points[i].y, this.fLineStyle.getPen())
        }

        if (this.fClosedPolygon) {
            gdiPlusCanvas.LineTo(this.Points[0].x, this.Points[0].y, this.fLineStyle.getPen())
        }

        this.fLineStyle.getPen().restoreDashPattern(gdiPlusCanvas.graphics.Context)
    }

    protected FillInside(): void {
        if (this.fPoints.length < 3 || !this.fShouldFillInside || !this.fClosedPolygon) {
            return
        }
        const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas
        gdiPlusCanvas.ClearPath()
        gdiPlusCanvas.MoveTo(this.fPoints[0].x, this.fPoints[0].y)
        for (let i = 1; i < 4; i++) {
            gdiPlusCanvas.LineToPath(this.fPoints[i].x, this.fPoints[i].y)
        }
        gdiPlusCanvas.LineToPath(this.fPoints[0].x, this.fPoints[0].y)
        gdiPlusCanvas.FillPath(this.brush)
    }
}
