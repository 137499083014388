import { TBasicPaintTool } from '@fto/lib/charting/paint_tools/BasicPaintTool'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import {
    TPaintToolStatus,
    TPaintToolType,
    TPointInfo,
    TPointsArr
} from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { TMouseButton } from '@fto/lib/delphi_compatibility/DelphiFormsBuiltIns'
import CommonConstants from '@fto/lib/ft_types/common/CommonConstants'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'

import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { BasicPaintToolJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'

export class TPtBrush extends TBasicPaintTool {
    private isDrawing: boolean
    private isStartDrawing: boolean
    private nextBrushToPaint: TPtBrush | null = null

    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptBrush)
        this.isDrawing = true
        this.isStartDrawing = false
        this.fToolType = TPaintToolType.tt_Line
        this.ToolName = 'Brush Tool'
        this.fMaxPoints = CommonConstants.MAX_INT
        this.fLineStyle.width = 2
        this.name = 'brush'

        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptBrush)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptBrush)
        }

        if (!styles) throw new StrangeError('Default styles for HLine are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptBrush, {
            lineStyle: this.fLineStyle.getSerialized(),
            toolName: PaintToolNames.ptBrush
        })

        if (this.nextBrushToPaint && this.nextBrushToPaint.isDrawing) {
            this.nextBrushToPaint.applySettings()
        }
    }

    public clone(): TPtBrush {
        const cloneObj = new TPtBrush(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        cloneObj.isDrawing = this.isDrawing
        cloneObj.isStartDrawing = this.isStartDrawing

        return cloneObj
    }

    public toJson(): BasicPaintToolJSON {
        return super.toJson()
    }

    public fromJSON(json: BasicPaintToolJSON): void {
        super.fromJSON(json)
    }

    public OnMouseDown(time: TDateTime, price: number, button: TMouseButton): void {
        super.OnMouseDown(time, price, button)
        if (button === TMouseButton.mbLeft && this.chart.IsMouseInside()) {
            this.isDrawing = true
            this.isStartDrawing = true
            this.AddPoint(time, price)

            const focusedToolOnChart = (this.chart as TMainChart).ChartWindow.getFocusedTool()
            focusedToolOnChart.update(this)
        }
    }

    public OnMouseMove(time: TDateTime, price: number): void {
        super.OnMouseMove(time, price, this.chart)
        if (this.isDrawing && this.isStartDrawing) {
            this.AddPoint(time, price)
            this.invalidate()
        }
    }

    public OnMouseUp(time: TDateTime, price: number, button: TMouseButton): void {
        super.OnMouseUp(time, price, button)

        if (button === TMouseButton.mbLeft && this.isDrawing && this.isStartDrawing) {
            this.isDrawing = false
            this.ReportEndOfWork()
            GlobalChartsController.Instance.registerPaintTool(PaintToolNames.ptBrush)
            this.nextBrushToPaint = TChartWindow.GetActiveTool() as TPtBrush
        }
        this.PrepareToMove()
    }

    private smoothPoints(points: TPointsArr, sigma = 1.0): TPointsArr {
        return this.gaussianSmooth(points, sigma)
    }

    private gaussianSmooth(points: TPointsArr, sigma: number): TPointsArr {
        const kernel = this.generateGaussianKernel(sigma, 7)

        const smoothX = this.applyConvolution(
            points.map((p: TPointInfo) => p.x),
            kernel
        )
        const smoothY = this.applyConvolution(
            points.map((p: TPointInfo) => p.y),
            kernel
        )

        const smoothedPoints = new TPointsArr()
        for (let i = 0; i < points.length; i++) {
            const point = points[i]
            const smoothedPoint = point.clone()
            if (i === 0 || i === points.length - 1) {
                smoothedPoint.x = point.x
                smoothedPoint.y = point.y
            } else {
                smoothedPoint.x = smoothX[i]
                smoothedPoint.y = smoothY[i]
            }
            smoothedPoint.time = smoothedPoint.x
            smoothedPoint.price = smoothedPoint.y
            smoothedPoints.push(smoothedPoint)
        }

        return smoothedPoints
    }

    private gaussianKernelCache: { [key: string]: number[] } = {}

    private generateGaussianKernel(sigma: number, kernelSize: number): number[] {
        const key = `${sigma}-${kernelSize}`
        if (this.gaussianKernelCache[key]) {
            return this.gaussianKernelCache[key]
        }
        const kernel: number[] = []
        const mean = Math.floor(kernelSize / 2)
        let sum = 0

        for (let x = 0; x < kernelSize; x++) {
            kernel[x] = Math.exp(-0.5 * Math.pow((x - mean) / sigma, 2)) / (sigma * Math.sqrt(2 * Math.PI))
            sum += kernel[x]
        }

        for (let i = 0; i < kernel.length; i++) {
            kernel[i] /= sum
        }

        this.gaussianKernelCache[key] = kernel
        return kernel
    }

    private applyConvolution(data: number[], kernel: number[]): number[] {
        const half = Math.floor(kernel.length / 2)
        const output: unknown[] = Array.from({ length: data.length }).fill(0)

        for (let i = 0; i < data.length; i++) {
            let value = 0
            for (const [j, element] of kernel.entries()) {
                const index = Math.min(Math.max(i + j - half, 0), data.length - 1)
                value += data[index] * element
            }
            output[i] = value
        }

        return output as number[]
    }

    public Paint(): void {
        const gdiPlusCanvas = this.fChart.GdiCanvas
        const pen = this.fLineStyle.getPen()

        if (this.fPoints.length === 0) {
            return
        }

        const smoothedPoints = this.smoothPoints(this.fPoints, 1.5)

        gdiPlusCanvas.MoveTo(smoothedPoints[0].x, smoothedPoints[0].y)
        if (this.fPoints.length <= 3 && this.status === TPaintToolStatus.ts_Completed) {
            const point = smoothedPoints[0]
            gdiPlusCanvas.drawCircle(point.x, point.y, 0.5, pen)
        } else if (this.fPoints.length <= 4) {
            for (let i = 1; i < this.fPoints.length; i++) {
                gdiPlusCanvas.LineTo(smoothedPoints[i].x, smoothedPoints[i].y, pen)
            }
        } else {
            const lengthMinus2 = smoothedPoints.length - 2
            for (let i = 0; i < lengthMinus2; i++) {
                const p0 = smoothedPoints[i === 0 ? i : i - 1]
                const p1 = smoothedPoints[i]
                const p2 = smoothedPoints[i + 1]
                const p3 = smoothedPoints[i + 2]

                const cp1x = p1.x + (p2.x - p0.x) / 6
                const cp1y = p1.y + (p2.y - p0.y) / 6
                const cp2x = p2.x - (p3.x - p1.x) / 6
                const cp2y = p2.y - (p3.y - p1.y) / 6

                gdiPlusCanvas.bezierCurve(p1.x, p1.y, cp1x, cp1y, cp2x, cp2y, p2.x, p2.y, pen)
            }

            const lastIndex = smoothedPoints.length - 2
            const p0 = smoothedPoints[lastIndex - 1]
            const p1 = smoothedPoints[lastIndex]
            const p2 = smoothedPoints[lastIndex + 1]
            const p3 = p2

            const cp1x = p1.x + (p2.x - p0.x) / 6
            const cp1y = p1.y + (p2.y - p0.y) / 6
            const cp2x = p2.x - (p3.x - p1.x) / 6
            const cp2y = p2.y - (p3.y - p1.y) / 6

            gdiPlusCanvas.bezierCurve(p1.x, p1.y, cp1x, cp1y, cp2x, cp2y, p2.x, p2.y, pen)
        }

        if (this.status === TPaintToolStatus.ts_Completed) {
            this.PaintMarkers()
        }
    }

    public PaintMarkers(): void {
        // paint only first and last points
        if (!this.MarkersVisible()) {
            return
        }
        this.PaintMarker_byNumber(0)
        this.PaintMarker_byNumber(this.fPoints.length - 1)
    }

    public OnMovePoint(index: number, time: TDateTime, price: number) {}

    public ExportToDialog(TemplateOnly = false): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },

            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, { toolName: 'brush' })
    }

    public ImportFromDialog(TemplateOnly = false): void {
        const { toolSettings, resetToolSettings } = GraphToolStore
        this.chart.ChartWindow.saveStateWithNotify()

        this.description = toolSettings.description.value
        this.fLineStyle = toolSettings.lineStyle.value

        this.saveToManager()
        resetToolSettings()
    }

    public AddPoint(time: TDateTime, price: number): void {
        const lastPoint = this.fPoints[this.fPoints.length - 1]

        const minDistance = 5

        if (lastPoint && this.fPoints.length > 2) {
            const dx = this.chart.GetXFromDate(time) - lastPoint.x
            const dy = this.chart.GetY(price) - lastPoint.y
            const distance = Math.hypot(dx, dy)

            if (distance < minDistance) {
                return
            }
        }

        super.AddPoint(time, price)
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptBrush, TPtBrush)
