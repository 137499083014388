import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { toolCollections, toolGannList } from '@fto/chart_components/constants/graphToolsList'
import { Icon, IconButton } from '@fto/icons'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import ToolInfoStore from '@fto/lib/store/tools'
import { Button, Flex, Tooltip } from '@fto/ui'
import { fireMixpanelEvent } from '@root/utils/api'

import Dropdown from './components/ToolsDropdown'

import styles from './index.module.scss'
import CustomActionsDropdown from './components/CustomActionsDropdown'
import { cursorModeDropdownOptions, deleteItemsDropdownOptions, tools } from './helpers/DropdownOptions'
import VisibilityDropdown from './components/VisibilityDropdown'
import { useTranslation } from 'react-i18next'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import ToolToggle from './components/ToolToggle'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'

export const ToolsSidebar: FC = observer(() => {
    const [activeToolName, setActiveToolName] = useState<string | null>(null)
    const { info } = ToolInfoStore
    const { t } = useTranslation()

    useEffect(() => {
        if (!info.isDrawing) {
            setActiveToolName(null)
        }
    }, [info.isDrawing])

    const registerPaintTool = useCallback((buttonName: string) => {
        fireMixpanelEvent('graph_tool_selected', { graph_tool_name: buttonName })
        setActiveToolName(buttonName)
        GlobalChartsController.Instance.registerPaintTool(buttonName)
    }, [])

    return (
        <Flex
            className={styles.graphicPanel}
            direction='column'
            gap={4}
            alignItems='center'
            justifyContent='space-between'
        >
            <Flex direction='column' gap={4} alignItems='center'>
                <CustomActionsDropdown options={cursorModeDropdownOptions(t)} header='cursors' isCursorDropdown />
                {toolCollections.map((collection) => {
                    return (
                        <Dropdown
                            key={collection.getName()}
                            collection={collection}
                            activeToolName={activeToolName}
                            setActiveToolName={setActiveToolName}
                            registerPaintTool={registerPaintTool}
                            additionalCategory={
                                collection.additionalCategory ? collection.additionalCategory : undefined
                            }
                        />
                    )
                })}
                {tools(t).map((tool) => {
                    return (
                        <Tooltip content={tool.name} placement='right' key={tool.toolKey}>
                            <div
                                className={styles.iconWrapper}
                                onClick={() => {
                                    registerPaintTool(tool.toolKey)
                                }}
                            >
                                {tool.icon && (
                                    <Icon
                                        color={
                                            activeToolName === tool.toolKey
                                                ? 'var(--color-primary-500)'
                                                : 'var(--color-gray-1000)'
                                        }
                                        size={18}
                                        name={tool.icon}
                                    />
                                )}
                            </div>
                        </Tooltip>
                    )
                })}
                <ToolToggle
                    value={GlobalOptions.Options.StrongMagnetMode}
                    action={() => {
                        GlobalOptions.Options.StrongMagnetMode = !GlobalOptions.Options.StrongMagnetMode
                    }}
                    option={{ name: t('graphicTools.toolNames.magnet'), icon: 'magnet', toolKey: 'Magnet' }}
                />
                <VisibilityDropdown anyHiddenObjects={info.anyHiddenIndicators || info.anyHiddenTools} />
                <CustomActionsDropdown options={deleteItemsDropdownOptions(t)} header='delete' />
            </Flex>
            <Flex direction='column' gap={4} alignItems='center'>
                <ToolToggle
                    value={GlobalOptions.Options.isSyncActionsWithGraphTools}
                    action={() => {
                        GlobalOptions.Options.isSyncActionsWithGraphTools =
                            !GlobalOptions.Options.isSyncActionsWithGraphTools

                        const activeTool = TChartWindow.GetActiveTool()

                        if (activeTool) {
                            activeTool.isSyncedWithOtherCharts = GlobalOptions.Options.isSyncActionsWithGraphTools
                        }
                    }}
                    option={{ name: t('graphicTools.toolNames.syncTools'), icon: 'link', toolKey: 'SyncTools' }}
                    size={18}
                />
            </Flex>
        </Flex>
    )
})
