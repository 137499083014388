import { IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'
import { CustomCursorPointers } from '@fto/lib/ft_types/common/CursorPointers'
import {
    ObjProp,
    TCoordsRect,
    TPaintToolStatus,
    TPaintToolType,
    TPointInfo,
    TPointsArray
} from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { DelphiColors, TPenStyle, TPoint, TRect } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { GannBoxJSON } from '@fto/lib/ProjectAdapter/Types'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TBasicPaintTool } from '@fto/lib/charting/paint_tools/BasicPaintTool'
import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { DelphiMathCompatibility } from '@fto/lib/delphi_compatibility/DelphiMathCompatibility'
import { LevelActiveType, LevelType, TLevelData, TLevelsList } from '@fto/lib/drawing_interface/GraphicObjects'
import { Common } from '@fto/lib/ft_types/common/Common'
import { TNoExactMatchBehavior } from '@fto/lib/ft_types/data/chunks/ChunkEnums'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'

export class TPtGannBox extends TBasicPaintTool {
    private brushForText: IGPSolidBrush
    public fLevels: TLevelsList | null
    public fLevelNo: number
    private decimalPrecision: number
    private horizontalText: boolean
    private verticalText: boolean
    public fLevelFillOpacity: number

    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptGannBox)
        this.fLineStyle = new TLineStyle(DelphiColors.clGray, TPenStyle.psSolid, 1)
        this.fLevels = new TLevelsList()
        this.fLevelNo = 0
        this.fMaxPoints = 2
        this.fToolType = TPaintToolType.tt_Polygon
        this.fClosedPolygon = true
        this.CursorStyle = CustomCursorPointers.crCursorRectangle
        this.icon = 58
        this.fToolType = TPaintToolType.tt_Polygon
        this.brushForText = new IGPSolidBrush(DelphiColors.clBlack)
        this.decimalPrecision = 3
        this.fShouldFillInside = true
        this.fLevelFillOpacity = 0.15
        this.brush.setColor('#bcbfc1')
        this.brush.setOpacity(0.15)
        this.horizontalText = true
        this.verticalText = true
        this.ShortName = 'Gann Box'
        const gann: number[] = [0, 0.25, 0.382, 0.5, 0.618, 0.75, 1]
        this.SetLevels(gann, this.decimalPrecision)
        this.saveToManager()
        this.applySettingsFromManager()
    }
    public clone(): TPtGannBox {
        const cloneObj = new TPtGannBox(this.fChart)
        const baseClone = super.clone()
        if (this.fLevels) {
            cloneObj.fLevels = this.fLevels?.clone()
            cloneObj.fLevelNo = this.fLevelNo
        }
        cloneObj.verticalText = this.verticalText
        cloneObj.horizontalText = this.horizontalText
        Object.assign(cloneObj, baseClone)
        return cloneObj
    }

    public assign(tool: TPtGannBox, isCopy = false): void {
        super.assign(tool, isCopy)
        if (this.fLevels && tool.fLevels) {
            this.fLevels.Assign(tool.fLevels)
        }
        this.verticalText = tool.verticalText
        this.horizontalText = tool.horizontalText
    }
    public toJson(): GannBoxJSON {
        const baseJson = super.toJson()
        const levels = this.fLevels ? this.fLevels.ExportToStr(this.decimalPrecision) : ''
        return {
            ...baseJson,
            brushForText: {
                color: this.brushForText.getColor(),
                opacity: this.brushForText.getOpacity(),
                style: this.brushForText.getStyle()
            },
            Levels: levels,
            LevelNo: this.fLevelNo,
            horizontalText: this.horizontalText,
            verticalText: this.verticalText
        }
    }
    public fromJSON(json: GannBoxJSON): void {
        super.fromJSON(json)
        this.brushForText.setColor(json.brushForText.color)
        this.brushForText.setOpacity(json.brushForText.opacity)
        this.brushForText.setStyle(json.brushForText.style)
        if (this.fLevels) {
            this.fLevels.Clear()
            this.fLevels.ImportFromStr(json.Levels)
            this.fLevelNo = json.LevelNo
        }
        this.verticalText = json.verticalText
        this.horizontalText = json.horizontalText
    }

    public SetLevels(levels: number[], decimals: number): void {
        if (!this.fLevels) {
            return
        }
        this.fLevels.Clear()

        const colors = ['#d1d1d1', '#808000', '#30755b', '#008000', '#9a5e5e', '#254883', '#d1d1d1']

        for (const [i, levelValue] of levels.entries()) {
            const levelText = this.StrDouble(levelValue, decimals)
            const lineStyle = new TLineStyle(colors[i % colors.length], TPenStyle.psSolid, 1)
            const levelData = TLevelData.Create_TLevelData_Style(
                levelValue,
                levelText,
                lineStyle,
                LevelActiveType.active
            )
            this.fLevels.Add(levelData)
        }
    }
    public updateLevelsFromModal(levels: LevelType[]): void {
        if (this.fLevels === null) {
            return
        }
        this.fLevels.Clear()

        for (const level of levels) {
            const lineStyle = new TLineStyle(
                ColorHelperFunctions.MakeColor(level.color as string),
                level.style as TPenStyle,
                level.width as number
            )

            const levelText = this.StrDouble(Number(level.value), this.decimalPrecision)
            const levelData = TLevelData.Create_TLevelData_Style(
                Number(level.value),
                String(levelText),
                lineStyle,
                level.isActive ? LevelActiveType.active : LevelActiveType.inactive,
                level.opacity as number
            )

            this.fLevels.Add(levelData)
        }
    }
    public SetProperty(index: number, value: unknown): void {
        if (!this.fLevels) {
            return
        }
        switch (index) {
            case ObjProp.OBJPROP_FIBOLEVELS: {
                this.fLevels.Clear()
                for (let i = 0; i < Number(value); i++) {
                    const tLevelData = new TLevelData('0')
                    tLevelData.text = '0.00'
                    tLevelData.style = new TLineStyle(
                        ColorHelperFunctions.MakeColor(DelphiColors.clGreen),
                        TPenStyle.psSolid,
                        1
                    )
                    this.fLevels.Add(tLevelData)
                }
                break
            }

            case ObjProp.OBJPROP_FIBOLEVELN: {
                this.fLevelNo =
                    this.fLevels.Count > 0 ? Common.fitNumberIntoRange(Number(value), 0, this.fLevels.Count - 1) : 0
                break
            }

            case ObjProp.OBJPROP_LEVELCOLOR: {
                if (this.fLevels.IndexValid(this.fLevelNo)) {
                    this.fLevels[this.fLevelNo].style.color = ColorHelperFunctions.MakeColor(Number(value).toString())
                }
                break
            }

            case ObjProp.OBJPROP_LEVELSTYLE: {
                if (this.fLevels.IndexValid(this.fLevelNo)) {
                    this.fLevels[this.fLevelNo].style.style = Number(value) as TPenStyle
                }
                break
            }

            case ObjProp.OBJPROP_LEVELWIDTH: {
                if (this.fLevels.IndexValid(this.fLevelNo)) {
                    this.fLevels[this.fLevelNo].style.width = Number(value)
                }
                break
            }

            case ObjProp.OBJPROP_LEVELVALUE: {
                if (this.fLevels.IndexValid(this.fLevelNo)) {
                    const levelValue = Number(value)
                    this.fLevels[this.fLevelNo].value = levelValue
                    this.fLevels[this.fLevelNo].text = this.StrDouble(levelValue, this.decimalPrecision)
                }
                break
            }

            default: {
                super.SetProperty(index, value)
            }
        }
    }
    public OnComplete(): void {
        this.fMaxPoints = 4
        this.AdjustRectangle()
    }
    private LoopIndex(index: number, max: number): number {
        return DelphiMathCompatibility.Mod(index, max)
    }
    public OnMovePoint(index: number, time: TDateTime, price: number): void {
        super.OnMovePoint(index, time, price)

        const idx1: number = this.LoopIndex(index - 1, 4)
        const idx2: number = this.LoopIndex(index + 1, 4)

        if (index === 1 || index === 3) {
            this.fPoints[idx1].price = price
            this.fPoints[idx2].time = time
        } else if (index === 0 || index === 2) {
            this.fPoints[idx1].time = time
            this.fPoints[idx2].price = price
        }

        switch (index) {
            case 4: {
                this.fPoints[0].price = price
                this.fPoints[1].price = price

                break
            }
            case 5: {
                this.fPoints[1].time = time
                this.fPoints[2].time = time

                break
            }
            case 6: {
                this.fPoints[2].price = price
                this.fPoints[3].price = price

                break
            }
            case 7: {
                this.fPoints[3].time = time
                this.fPoints[0].time = time

                break
            }
        }
    }

    protected AdjustRectangle(): void {
        const R: TCoordsRect = this.GetCoordsRect()
        this.fPoints.clear()

        this.addPoint(R.x1, R.y1)
        this.addPoint(R.x2, R.y1)
        this.addPoint(R.x2, R.y2)
        this.addPoint(R.x1, R.y2)
    }
    private addPoint(x: number, y: number): void {
        const point = new TPointInfo(x, y)
        point.time = x
        point.price = y
        this.fPoints.add(point)
    }

    public ExportToDialog(TemplateOnly = false): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context
        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            },
            fillInsideColor: {
                key: 'fillInsideColor',
                value: {
                    color: ColorHelperFunctions.BasicColor(this.brush.getColor()),
                    isActive: this.fShouldFillInside,
                    opacity: this.brush.getOpacity()
                },
                label: 'background',
                withOpacity: true,
                type: 'fillColor',
                isOptional: true
            },
            levels: {
                value: this.fLevels?.map((level) => ({
                    id: level.id,
                    value: level.value,
                    text: level.text,
                    isActive: level.isActive,
                    style: level.pen.getPenStyleFromPattern(),
                    color: level.pen.color,
                    width: level.pen.width,
                    opacity: level.pen.opacity
                })),
                type: 'levels',
                key: 'levels',
                label: 'levels'
            },

            horizontalText: {
                key: 'horizontalText',
                value: this.horizontalText,
                label: 'toolsModal.fields.horizontalText',
                type: 'checkbox',
                disabled: false
            },
            verticalText: {
                key: 'verticalText',
                value: this.verticalText,
                label: 'toolsModal.fields.verticalText',
                type: 'checkbox',
                disabled: false
            }
        }
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, { toolName: 'gannBox' })
    }

    public ImportFromDialog(TemplateOnly = false): void {
        const { getKeyValueData, resetToolSettings } = GraphToolStore // Use the store/context
        const formattedToolSettings = getKeyValueData()
        const { description, lineStyle, fillInsideColor, levels, horizontalText, verticalText } = formattedToolSettings
        this.chart.ChartWindow.saveStateWithNotify()
        this.description = description
        this.fLineStyle = lineStyle.clone()
        this.fPoints[1].price = this.fPoints[0].price
        this.fPoints[1].time = this.fPoints[2].time
        this.fPoints[3].price = this.fPoints[2].price
        this.fPoints[3].time = this.fPoints[0].time
        this.fShouldFillInside = fillInsideColor.isActive
        this.verticalText = verticalText
        this.horizontalText = horizontalText
        this.updateLevelsFromModal(levels)
        this.brush.setColor(fillInsideColor.color)
        this.brush.setOpacity(fillInsideColor.opacity)
        this.saveToManager()
        resetToolSettings()
    }
    public override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }): void {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }
    public override setFillColorParams(color: string, opacity: number): void {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }
    public override setFontStyles(color: string, fontSize: number): void {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    public saveToManager(): void {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptGannBox, {
            toolName: PaintToolNames.ptGannBox,
            lineStyle: this.fLineStyle.getSerialized(),
            levels: this.fLevels?.ExportToStr(this.decimalPrecision),
            fShouldFillInside: this.fShouldFillInside
        })
    }
    public applySettingsFromManager(): void {
        const styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptGannBox)
        if (!styles) return
        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
        if (this.fLevels && styles.levels) {
            this.fLevels.ImportFromStr(styles.levels)
        }
    }

    protected FillInside(): void {
        const gdiCanvas = this.fChart.GdiCanvas

        if (this.fStatus === TPaintToolStatus.ts_Completed || this.fPoints.Count < 2 || !this.fShouldFillInside) {
            if (this.fPoints.length < 3 || !this.fShouldFillInside || !this.fClosedPolygon) {
                return
            }
            const gdiPlusCanvas: TGdiPlusCanvas = this.fChart.GdiCanvas
            gdiPlusCanvas.ClearPath()
            gdiPlusCanvas.MoveTo(this.fPoints[0].x, this.fPoints[0].y)
            for (let i = 1; i < this.fPoints.length; i++) {
                gdiPlusCanvas.LineToPath(this.fPoints[i].x, this.fPoints[i].y)
            }
            gdiPlusCanvas.LineToPath(this.fPoints[0].x, this.fPoints[0].y)
            gdiPlusCanvas.FillPath(this.brush)
        } else {
            if (!gdiCanvas) {
                throw new Error('GdiCanvas is not available on the fChart object.')
            }

            gdiCanvas.ClearPath()

            const arr: TPointsArray = this.getPoints()
            if (arr.length === 0) {
                throw new Error('The points array is empty.')
            }

            gdiCanvas.MoveTo(arr[0].x, arr[0].y)
            for (let i = 1; i < 4; i++) {
                gdiCanvas.LineToPath(arr[i].x, arr[i].y)
            }

            gdiCanvas.LineToPath(arr[0].x, arr[0].y)

            gdiCanvas.FillPath(this.brush)
        }
    }
    public StrDouble(
        value: number,
        precision = 2,
        width = 0,
        symbol = ' ',
        thousandSeparator = ',',
        decimalSeparator = '.'
    ): string {
        if (symbol.length !== 1) {
            throw new Error('Symbol must be a single character')
        }

        let result: string = value.toFixed(precision).replace(/\.?0+$/, '')

        if (decimalSeparator !== '.') {
            result = result.replace('.', decimalSeparator)
        }

        if (thousandSeparator && thousandSeparator !== '') {
            const parts = result.split(decimalSeparator)
            parts[0] = parts[0].replaceAll(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
            result = parts.join(decimalSeparator)
        }

        const absWidth: number = Math.abs(width)
        if (absWidth > result.length) {
            const padding: string = symbol.repeat(absWidth - result.length)
            result = width < 0 ? padding + result : result + padding
        }

        return result
    }

    public Paint(): void {
        this.PaintLines()

        if (this.fText) {
            const rectWidth = Math.abs(this.fPoints[0].x - this.fPoints[2].x)
            const textWidth = this.font.getTextWidthByContext(this.fText, this.fChart.GdiCanvas.graphics.Context)

            if ((textWidth / rectWidth) * 100 < 250) {
                this.fChart.GdiCanvas.textOutByTmkFontStyle(
                    this.fPoints[0].x,
                    this.fPoints[0].y,
                    this.fText,
                    this.fFontStyle
                )
            }
        }
        if (
            this.fStatus === TPaintToolStatus.ts_Completed &&
            this.fLevels &&
            this.fPoints[0].x !== this.fPoints[1].x &&
            this.fPoints[0].y !== this.fPoints[3].y
        ) {
            this.fLevels.SortByValue()
            const canvas = this.fChart.GdiCanvas
            const index1 = this.fChart.GetGlobalIndexByDate(
                this.fPoints[0].time,
                TNoExactMatchBehavior.nemb_ReturnNearestLower,
                false
            )
            const index2 = this.fChart.GetGlobalIndexByDate(
                this.fPoints[1].time,
                TNoExactMatchBehavior.nemb_ReturnNearestLower,
                false
            )
            const scale = this.fChart.ChartOptions.GetScaleInfo()

            const xStart = this.fPoints[0].x
            const xEnd = this.fPoints[1].x

            const yStart = this.fPoints[0].y
            const yEnd = this.fPoints[3].y

            let previousYLevel = yStart
            let previousXLevel = xStart

            const minWidth = 2
            const minHeight = 2
            for (let i = 0; i < this.fLevels.Count; i++) {
                const level = this.fLevels[i]
                if (!level.isActive) {
                    continue
                }

                level.value = Math.min(level.value, 1)
                level.value = Math.max(level.value, 0)
                level.text = this.StrDouble(level.value, this.decimalPrecision)

                const yLevel = yStart + (yEnd - yStart) * level.value
                const barIndexOffset = Math.round(level.value * (index2 - index1))
                const xLevel = xStart + barIndexOffset * scale.PixBetweenBars

                level.brush.setOpacity(this.fLevelFillOpacity)

                if (Math.abs(yLevel - previousYLevel) >= minHeight) {
                    const rectY = new TRect(xStart, previousYLevel, xEnd, yLevel)
                    canvas.FillRect(rectY, level.brush)
                } else {
                    canvas.MoveTo(Math.round(xStart) + 0.5, Math.round(previousYLevel) + 0.5)
                    canvas.LineTo(Math.round(xEnd) + 0.5, Math.round(previousYLevel) + 0.5, level.pen)
                }

                if (Math.abs(xLevel - previousXLevel) >= minWidth) {
                    const rectX = new TRect(previousXLevel, yStart, xLevel, yEnd)
                    canvas.FillRect(rectX, level.brush)
                } else {
                    canvas.MoveTo(Math.round(previousXLevel) + 0.5, Math.round(yStart) + 0.5)
                    canvas.LineTo(Math.round(previousXLevel) + 0.5, Math.round(yEnd) + 0.5, level.pen)
                }

                previousYLevel = yLevel
                previousXLevel = xLevel

                canvas.MoveTo(Math.round(xStart) + 0.5, Math.round(yLevel) + 0.5)
                canvas.LineTo(Math.round(xEnd) + 0.5, Math.round(yLevel) + 0.5, level.pen)

                if (this.verticalText) {
                    const yText = level.text
                    const yTextWidth = this.fChart.GdiCanvas.TextWidth(yText)

                    if (this.fPoints[0].x < this.fPoints[1].x) {
                        canvas.textOut(
                            Math.round(xEnd) + 0.5 + 10,
                            Math.round(yLevel) + 0.5,
                            yText,
                            this.font,
                            level.brush
                        )
                        canvas.textOut(
                            Math.round(xStart) + 0.5 - 10 - yTextWidth,
                            Math.round(yLevel) + 0.5,
                            yText,
                            this.font,
                            level.brush
                        )
                    } else {
                        canvas.textOut(
                            Math.round(xEnd) + 0.5 - 10 - yTextWidth,
                            Math.round(yLevel) + 0.5,
                            yText,
                            this.font,
                            level.brush
                        )
                        canvas.textOut(
                            Math.round(xStart) + 0.5 + 10,
                            Math.round(yLevel) + 0.5,
                            yText,
                            this.font,
                            level.brush
                        )
                    }
                }

                canvas.MoveTo(Math.round(xLevel) + 0.5, Math.round(yStart) + 0.5)
                canvas.LineTo(Math.round(xLevel) + 0.5, Math.round(yEnd) + 0.5, level.pen)

                if (this.horizontalText) {
                    const xText = level.text
                    const xTextWidth = this.fChart.GdiCanvas.TextWidth(xText)
                    const xTextHeight = this.fChart.GdiCanvas.TextHeight('0')

                    if (this.fPoints[0].y < this.fPoints[3].y) {
                        canvas.textOut(
                            Math.round(xLevel) + 0.5 - xTextWidth / 2,
                            Math.round(yEnd) + 0.5 + xTextHeight + 10,
                            xText,
                            this.font,
                            level.brush
                        )
                        canvas.textOut(
                            Math.round(xLevel) + 0.5 - xTextWidth / 2,
                            Math.round(yStart) + 0.5 - xTextHeight,
                            xText,
                            this.font,
                            level.brush
                        )
                    } else {
                        canvas.textOut(
                            Math.round(xLevel) + 0.5 - xTextWidth / 2,
                            Math.round(yEnd) + 0.5 - xTextHeight,
                            xText,
                            this.font,
                            level.brush
                        )
                        canvas.textOut(
                            Math.round(xLevel) + 0.5 - xTextWidth / 2,
                            Math.round(yStart) + 0.5 + xTextHeight + 10,
                            xText,
                            this.font,
                            level.brush
                        )
                    }
                }
            }
        }
        this.PaintMarkers()
    }

    protected PaintLines(): void {
        if (this.fHighlighted) {
            const points = [
                new TPoint(Math.round(this.fPoints[0].x) + 0.5, Math.round(this.fPoints[0].y) + 0.5),
                new TPoint(Math.round(this.fPoints[1].x) + 0.5, Math.round(this.fPoints[1].y) + 0.5),
                new TPoint(Math.round(this.fPoints[2].x) + 0.5, Math.round(this.fPoints[2].y) + 0.5),
                new TPoint(Math.round(this.fPoints[3].x) + 0.5, Math.round(this.fPoints[3].y) + 0.5)
            ]
            this.PaintHoverLine(points)
        }
        if (this.fStatus === TPaintToolStatus.ts_Completed || this.fPoints.length < 2) {
            if (this.isNeedDrawBorder) {
                super.PaintLines()
            }
        } else {
            const gdiCanvas = this.fChart.GdiCanvas
            const arr: TPointsArray = this.getPoints()
            gdiCanvas.MoveTo(Math.round(arr[0].x) + 0.5, Math.round(arr[0].y) + 0.5)
            for (let i = 1; i <= 3; i++) {
                gdiCanvas.LineTo(Math.round(arr[i].x) + 0.5, Math.round(arr[i].y) + 0.5, this.fLineStyle.getPen())
            }
            gdiCanvas.LineTo(Math.round(arr[0].x) + 0.5, Math.round(arr[0].y) + 0.5, this.fLineStyle.getPen())
            this.PaintText(gdiCanvas)
        }
    }

    private PaintText(canvas: TGdiPlusCanvas): void {
        const R: TCoordsRect = this.GetCoordsRect()

        const x1: number = this.chart.GetXFromDate(R.x1)
        const x2: number = this.chart.GetXFromDate(R.x2)
        const y2: number = this.chart.GetY(R.y2)

        const index1: number = this.chart.GetIndexFromX(x1)
        const index2: number = this.chart.GetIndexFromX(x2)

        const w: number = Math.abs(index2 - index1)
        const h: number = Math.abs(R.y1 - R.y2)

        const s: string = (h * 10 ** this.chart.ScaleDecimals()).toFixed(0)

        canvas.textOut(x2 + 3, y2 + 3, `${w} / ${s}`, this.font, this.brushForText)
    }
    public getPoints(): TPointsArray {
        const x1 = Math.min(this.fPoints[0].x, this.fPoints[1].x)
        const x2 = Math.max(this.fPoints[0].x, this.fPoints[1].x)
        const y1 = Math.min(this.fPoints[0].y, this.fPoints[1].y)
        const y2 = Math.max(this.fPoints[0].y, this.fPoints[1].y)

        return [new TPoint(x1, y1), new TPoint(x2, y1), new TPoint(x2, y2), new TPoint(x1, y2)]
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptGannBox, TPtGannBox)
