import { t } from 'i18next'
import { OptionType } from 'packages/fto/root/src/chart_components/ProjectInterface/components/ToolsSidebar/types'

export const CURSORS_LIST = []

export const getLinesList = () => [
    { name: t('graphicTools.toolNames.vLine'), icon: 'vline', toolKey: 'vLine' },
    { name: t('graphicTools.toolNames.hLine'), icon: 'hline', toolKey: 'ptHLine' },
    { name: t('graphicTools.toolNames.trendLine'), icon: 'tline', toolKey: 'Trend Line' },
    { name: t('graphicTools.toolNames.ray'), icon: 'ray', toolKey: 'Ray' },
    { name: t('graphicTools.toolNames.polyline'), icon: 'polyline', toolKey: 'Polyline' }
]

export const getShapesList = () => [
    { name: t('graphicTools.toolNames.rectangle'), icon: 'rectangle', toolKey: 'Rectangle' },
    { name: t('graphicTools.toolNames.ellipse'), icon: 'ellipse', toolKey: 'Ellipse' },
    { name: t('graphicTools.toolNames.triangle'), icon: 'triangle', toolKey: 'Triangle' }
]

export const getRiskRewardList = () => [
    { name: t('graphicTools.toolNames.rrBuy'), icon: 'rr-buy', toolKey: 'Risk/Reward buy' },
    { name: t('graphicTools.toolNames.rrSell'), icon: 'rr-sell', toolKey: 'Risk/Reward sell' }
]

export const getObjectsList = () => [
    { name: t('graphicTools.toolNames.arrowUp'), icon: 'arrow-up', toolKey: 'Arrow Up' },
    { name: t('graphicTools.toolNames.arrowDown'), icon: 'arrow-down', toolKey: 'Arrow Down' },
    { name: t('graphicTools.toolNames.thumbUp'), icon: 'thumbs-up', toolKey: 'Thumb Up' },
    { name: t('graphicTools.toolNames.thumbDown'), icon: 'thumbs-down', toolKey: 'Thumb Down' },
    { name: t('graphicTools.toolNames.check'), icon: 'check-lined', toolKey: 'Check' },
    { name: t('graphicTools.toolNames.stop'), icon: 'stop', toolKey: 'Stop' },
    { name: t('graphicTools.toolNames.priceLabelRight'), icon: 'right-price-label', toolKey: 'Price Label Right' },
    { name: t('graphicTools.toolNames.priceLabelLeft'), icon: 'left-price-label', toolKey: 'Price Label Left' },
    {
        name: t('graphicTools.toolNames.priceLabel'),
        icon: 'price-label',
        toolKey: 'Price Label'
    }
]

export const getChannelsList = () => [
    { name: t('graphicTools.toolNames.fiboChannel'), icon: 'fibo-channel', toolKey: 'Fibo Channel' },
    { name: t('graphicTools.toolNames.regressionChannel'), icon: 'regression-channel', toolKey: 'Regression Channel' }
]

export const getFiboList = () => [
    { name: t('graphicTools.toolNames.fiboRetracement'), icon: 'fibo-retracement', toolKey: 'Fibo Retracement' },
    { name: t('graphicTools.toolNames.fiboTimezones'), icon: 'fibo-timezones', toolKey: 'Fibo Time Zones' },
    { name: t('graphicTools.toolNames.fiboFan'), icon: 'fibo-fan', toolKey: 'Fibo Fan' },
    { name: t('graphicTools.toolNames.fiboArc'), icon: 'fibo-arc', toolKey: 'Fibo Arc' },
    { name: t('graphicTools.toolNames.fiboExtension'), icon: 'fibo-extension', toolKey: 'Fibo Extension' }
]

export const getGannList = () => [{ name: t('graphicTools.toolNames.gannBox'), icon: 'gann-box', toolKey: 'Gann Box' }]

export const toolGannList = { list: getGannList, getName: () => t('graphicTools.groupNames.gann') } as {
    list: () => OptionType[]
    getName: () => string
}

export const toolCollections = [
    //{ list: CURSORS_LIST, icon: 'cursors' },
    { list: getLinesList, getName: () => t('graphicTools.groupNames.lines') },
    { list: getShapesList, getName: () => t('graphicTools.groupNames.shapes') },
    { list: getRiskRewardList, getName: () => t('graphicTools.groupNames.rrTool') },
    { list: getObjectsList, getName: () => t('graphicTools.groupNames.objects') },
    { list: getChannelsList, getName: () => t('graphicTools.groupNames.channels') },
    { list: getFiboList, getName: () => t('graphicTools.groupNames.fibo'), additionalCategory: toolGannList }
] as {
    list: () => OptionType[]
    getName: () => string
    additionalCategory?: {
        list: () => OptionType[]
        getName: () => string
    }
}[]
