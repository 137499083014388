import { useCallback, useEffect, useMemo, useRef } from 'react'
import { observer } from 'mobx-react-lite'

import Project from '@fto/chart_components/ProjectInterface'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'
import { SyncProjectManager } from '@fto/lib/ProjectAdapter/ProjectSync/SyncProjectManager'
import ProjectStore from '@fto/lib/store/projectStore'
import ModalsWrapper from '@root/pages/ChartPage/components/ModalsWrapper'
import { fireMixpanelEvent } from '@root/utils/api'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { $getProjects, $getProjectsStatus } from '@root/store/projects/projects.selector'
import { GET_PROJECTS_ACTION } from '@root/store/projects/project.actions'
import { GET_SYMBOLS_ACTION } from '@root/store/symbols/symbols.actions'
import { $getSymbolsProcessData } from '@root/store/symbols/symbol.selectors'
import { BarForwardBarBackCounterType } from '@fto/chart_components/ProjectInterface/types'

import ErrorState from './components/ErrorState'

import styles from './index.module.scss'
import { $getUser } from '@root/store/auth/selectors'
import { resetStores } from './utils/resetStores'
import { hideModals } from './utils/hideModals'
import AppLoader from '@root/components/AppLoader'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'

const ChartPage = observer(() => {
    const {
        data: { isLoading, isError }
    } = ProjectStore
    const projects = useAppSelector($getProjects)
    const { userId } = useAppSelector($getUser)
    const { loading: symbolsLoading } = useAppSelector($getSymbolsProcessData)
    const status = useAppSelector($getProjectsStatus)
    const dispatch = useAppDispatch()

    const barForwardBarBackEventsRef = useRef<BarForwardBarBackCounterType>({
        bar_back_old: 0,
        bar_back_new: 0,
        bar_forward_old: 0,
        bar_forward_new: 0
    })

    useEffect(() => {
        if (projects === null && status === 'idle') {
            dispatch(GET_PROJECTS_ACTION())
        }

        if (symbolsLoading === 'idle') {
            dispatch(GET_SYMBOLS_ACTION())
        }
    }, [projects])

    useEffect(() => {
        return () => {
            resetStores()
            hideModals()
        }
    }, [resetStores])

    useEffect(() => {
        if (userId) {
            DebugUtils.logTopic(ELoggingTopics.lt_Loading, 'ChartPage: useEffect userId', userId)
            const params = new URLSearchParams(window.location.search)

            const projectId = params.get('projectId')

            GlobalProjectJSONAdapter.Instance.Initialize(projectId, userId)
        }
    }, [userId])

    useEffect(() => {
        // NOTE: beforeunload should be only in mount
        const handleProjectClosing = () => {
            SyncProjectManager.Instance.SaveProject()
            SyncProjectManager.Instance.destroy()
        }

        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault()
            handleProjectClosing()
            fireMixpanelEvent('project_closed', GlobalProjectJSONAdapter.Instance.getProjectInfoForStatistics())
            fireMixpanelEvent('total_hotkeys_used', barForwardBarBackEventsRef.current)
        }

        window.addEventListener('beforeunload', handleBeforeUnload)
        window.addEventListener('unload', handleProjectClosing)

        // Cleanup the event listener on component unmount
        return () => {
            GlobalProjectJSONAdapter.Instance.saveProjectOnClose()

            const didChartMoveByHotKey = Object.values(barForwardBarBackEventsRef.current).some((count) => count > 0)

            if (didChartMoveByHotKey) {
                fireMixpanelEvent('total_hotkeys_used', barForwardBarBackEventsRef.current)
            }

            fireMixpanelEvent('project_closed', GlobalProjectJSONAdapter.Instance.getProjectInfoForStatistics())

            window.removeEventListener('beforeunload', handleBeforeUnload)
            window.removeEventListener('unload', handleProjectClosing)
        }
    }, [])

    const setBarForwardBarBackEventsTotalCount = useCallback(
        (updater: (prevValue: BarForwardBarBackCounterType) => BarForwardBarBackCounterType) => {
            barForwardBarBackEventsRef.current = updater(barForwardBarBackEventsRef.current)
        },
        []
    )

    const isPageLoading = useMemo(() => {
        const loadingStates = ['idle', 'pending']
        return isLoading || loadingStates.includes(status) || loadingStates.includes(symbolsLoading)
    }, [isLoading, status, symbolsLoading])

    const isPageError = useMemo(() => {
        return isError || status === 'failed' || symbolsLoading === 'failed'
    }, [isError, status, symbolsLoading])

    if (isPageError) {
        return <ErrorState />
    }

    if (isPageLoading) {
        return <AppLoader size={64} />
    }

    return (
        <div className={styles.ChartPage}>
            <Project setBarForwardBarBackEventsTotalCount={setBarForwardBarBackEventsTotalCount} />
            <ModalsWrapper />
        </div>
    )
})

export default ChartPage
