import { TRectPaintTool } from './ptRect'
import GraphToolStore from '@fto/lib//charting/tool_storages/graphToolStore'
import { RectangleJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'
import {
    TCoordsRect,
    TPaintToolStatus,
    TPaintToolType,
    TPointsArray
} from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { DelphiColors, TPoint } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { CustomCursorPointers } from '@fto/lib/ft_types/common/CursorPointers'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TChart } from '../../chart_classes/BasicChart'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'

export class TPtRectangle extends TRectPaintTool {
    private brushForText: IGPSolidBrush

    constructor(aChart: TChart) {
        super(aChart, PaintToolNames.ptRectangle) // Call the parent class constructor with the chart instance

        this.fClosedPolygon = true // Indicates that the rectangle is a closed polygon
        this.CursorStyle = CustomCursorPointers.crCursorRectangle // Set the cursor style for the rectangle tool
        this.icon = 58 // Assign an icon index for the tool
        this.fToolType = TPaintToolType.tt_Polygon
        this.brushForText = new IGPSolidBrush(DelphiColors.clBlack)

        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptRectangle)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptRectangle)
        }

        if (!styles) throw new StrangeError('Default styles for Rectangle are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
        this.brush = IGPSolidBrush.fromSerialized(styles.fillingStyle)
        this.fShouldFillInside = styles.fShouldFillInside
        this.fExtendsRay.left = styles.extendLeft
        this.fExtendsRay.right = styles.extendRight
    }

    clone(): TPtRectangle {
        const cloneObj = new TPtRectangle(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        return cloneObj
    }

    public toJson(): RectangleJSON {
        const baseJson = super.toJson()
        return {
            ...baseJson,
            brushForText: {
                color: this.brushForText.getColor(),
                opacity: this.brushForText.getOpacity(),
                style: this.brushForText.getStyle()
            }
        }
    }

    public fromJSON(json: RectangleJSON): void {
        super.fromJSON(json)
        this.brushForText.setColor(json.brushForText.color)
        this.brushForText.setOpacity(json.brushForText.opacity)
        this.brushForText.setStyle(json.brushForText.style)
    }

    ExportToDialog(): void {
        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            description: {
                value: this.description,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'description',
                disabled: false
            },
            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            },
            fillInsideColor: {
                key: 'fillInsideColor',
                value: {
                    color: ColorHelperFunctions.BasicColor(this.brush.getColor()),
                    isActive: this.fShouldFillInside,
                    opacity: this.brush.getOpacity()
                },
                label: 'background',
                withOpacity: true,
                type: 'fillColor',
                isOptional: true
            },
            extendLeft: {
                key: 'extendLeft',
                value: this.fExtendsRay.left,
                label: 'Extend to the left',
                type: 'checkbox',
                disabled: false,
                isOptional: true
            },
            extendRight: {
                key: 'extendRight',
                value: this.fExtendsRay.right,
                label: 'Extend to the right',
                type: 'checkbox',
                disabled: false,
                isOptional: true
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, { toolType: PaintToolNames.ptRectangle, toolName: 'rectangle' })
    }

    ImportFromDialog(): void {
        const { getKeyValueData, resetToolSettings } = GraphToolStore // Use the store/context
        const formattedToolSettings = getKeyValueData()
        const { description, lineStyle, fillInsideColor, extendLeft, extendRight } = formattedToolSettings

        this.chart.ChartWindow.saveStateWithNotify()

        this.description = description
        this.fLineStyle = lineStyle.clone()
        this.fPoints[1].price = this.fPoints[0].price
        this.fPoints[1].time = this.fPoints[2].time
        this.fPoints[3].price = this.fPoints[2].price
        this.fPoints[3].time = this.fPoints[0].time
        this.fExtendsRay.left = extendLeft
        this.fExtendsRay.right = extendRight
        this.AdjustRectangle()
        this.fShouldFillInside = fillInsideColor.isActive

        this.brush.setColor(fillInsideColor.color)
        this.brush.setOpacity(fillInsideColor.opacity)
        this.saveToManager()
        resetToolSettings()
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }

    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptRectangle, {
            toolName: PaintToolNames.ptRectangle,
            lineStyle: this.fLineStyle.getSerialized(),
            fillingStyle: this.brush.getSerialized(),
            fShouldFillInside: this.fShouldFillInside,
            extendLeft: this.fExtendsRay.left,
            extendRight: this.fExtendsRay.right
        })
    }

    protected FillInside(): void {
        const gdiCanvas = this.fChart.GdiCanvas

        // Check if the rectangle is completed, has less than 2 points, or if fill inside is not set
        if (this.fStatus === TPaintToolStatus.ts_Completed || this.fPoints.Count < 2 || !this.fShouldFillInside) {
            super.FillInside() // Call the parent method if the conditions are not met
        } else {
            // Ensure that GdiCanvas is available on the fChart object
            if (!gdiCanvas) {
                throw new StrangeError('GdiCanvas is not available on the fChart object.')
            }

            gdiCanvas.ClearPath()

            // GetPoints() is assumed to return an array of TPointsArray, which is the correct type
            const arr: TPointsArray = this.getPoints()
            if (arr.length === 0) {
                throw new StrangeError('The points array is empty.')
            }

            // Move to the starting point of the rectangle
            gdiCanvas.MoveTo(arr[0].x, arr[0].y)
            // Loop through the points array and create a path
            for (let i = 1; i < 4; i++) {
                gdiCanvas.LineToPath(arr[i].x, arr[i].y)
            }

            // Close the path by connecting the last point to the first
            gdiCanvas.LineToPath(arr[0].x, arr[0].y)

            // Set the brush for filling and fill the path
            gdiCanvas.FillPath(this.brush)
        }
        if (this.fPoints.length > 2) {
            this.PaintExtendsRay()
        }
    }

    Paint(): void {
        this.PaintLines()
        this.PaintMarkers(true, [4, 5, 6, 7])
        if (this.fText) {
            const rectWidth = Math.abs(this.fPoints[0].x - this.fPoints[2].x)

            const textWidth = this.font.getTextWidthByContext(this.fText, this.fChart.GdiCanvas.graphics.Context)
            if ((textWidth / rectWidth) * 100 < 250) {
                this.fChart.GdiCanvas.textOutByTmkFontStyle(
                    this.fPoints[0].x,
                    this.fPoints[0].y,
                    this.fText,
                    this.fFontStyle
                )
            }
        }
    }

    protected PaintLines(): void {
        if (this.fHighlighted) {
            const points = [
                new TPoint(this.fPoints[0].x, this.fPoints[0].y),
                new TPoint(this.fPoints[1].x, this.fPoints[1].y),
                new TPoint(this.fPoints[2].x, this.fPoints[2].y),
                new TPoint(this.fPoints[3].x, this.fPoints[3].y)
            ]
            this.PaintHoverLine(points)
        }
        if (this.fStatus === TPaintToolStatus.ts_Completed || this.fPoints.length < 2) {
            if (this.isNeedDrawBorder) {
                super.PaintLines()
            }
        } else {
            const gdiCanvas = this.fChart.GdiCanvas
            const arr: TPointsArray = this.getPoints()
            gdiCanvas.MoveTo(arr[0].x, arr[0].y)
            for (let i = 1; i <= 3; i++) {
                gdiCanvas.LineTo(arr[i].x, arr[i].y, this.fLineStyle.getPen())
            }
            gdiCanvas.LineTo(arr[0].x, arr[0].y, this.fLineStyle.getPen())
            this.PaintText(gdiCanvas)
        }
    }

    private PaintText(canvas: TGdiPlusCanvas): void {
        // Obtain the coordinates rectangle for the current points
        const R: TCoordsRect = this.GetCoordsRect()

        // Convert the rectangle's date/time values to chart coordinates
        const x1: number = this.chart.GetXFromDate(R.x1)
        const x2: number = this.chart.GetXFromDate(R.x2)
        const y2: number = this.chart.GetY(R.y2)

        // Calculate the indexes on the chart for the x coordinates
        const index1: number = this.chart.GetIndexFromX(x1)
        const index2: number = this.chart.GetIndexFromX(x2)

        // Calculate the width and height in chart units
        const w: number = Math.abs(index2 - index1)
        const h: number = Math.abs(R.y1 - R.y2)

        // Format the height value to a string with the appropriate number of decimal places
        const s: string = (h * 10 ** this.chart.ScaleDecimals()).toFixed(0)

        // Draw the text showing the width and height next to the rectangle
        canvas.textOut(x2 + 3, y2 + 3, `${w} / ${s}`, this.font, this.brushForText)
    }

    public PointToScreen(pointIndex: number): TPoint {
        const result = new TPoint(-1, -1)

        if (!DateUtils.IsEmpty(this.fPoints[pointIndex].time)) {
            result.x = this.chart.GetPreciseXFromDate(this.fPoints[pointIndex].time)
            result.y = this.chart.GetY(this.fPoints[pointIndex].price)
        }

        return result
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptRectangle, TPtRectangle)
