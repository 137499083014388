import StrangeError from '../common/common_errors/StrangeError'
import { DateUtils, TDateTime } from './DateUtils'

export type TColor = string // Simplified TColor

export enum DelphiColors {
    clBlack = '#000000',
    clMaroon = '#800000',
    clGreen = '#008000',
    clOlive = '#808000',
    clNavy = '#000080',
    clPurple = '#800080',
    clTeal = '#008080',
    clGray = '#808080',
    clSilver = '#C0C0C0',
    clRed = '#FF0000',
    clLime = '#00FF00',
    clYellow = '#FFFF00',
    clBlue = '#0000FF',
    clFuchsia = '#FF00FF',
    clAqua = '#00FFFF',
    clWhite = '#FFFFFF',
    clMoneyGreen = '#C0DCC0',
    clSkyBlue = '#87CEEB',
    clCream = '#FFFDD0',
    clMedGray = '#A0A0A4',
    clMediumAquamarine = '#66CDAA'
}

export enum TPenStyle {
    psSolid = 0,
    psDash = 1,
    psDot = 2,
    psDashDot = 3,
    psDashDotDot = 4,
    psClear = 5,
    psInsideFrame = 6,
    psUserStyle = 7,
    psAlternate = 8
}

export function GetPenStyle(value: number): TPenStyle {
    if (value in TPenStyle) {
        return value as TPenStyle
    }
    throw new StrangeError('Invalid value for TPenStyle')
}

export enum TBrushStyle {
    bsSolid,
    bsClear,
    bsHorizontal,
    bsVertical,
    bsFDiagonal,
    bsBDiagonal,
    bsCross,
    bsDiagCross
    // Add any other styles as needed
}

export function GetBrushStyleByNumber(value: number): TBrushStyle {
    if (TBrushStyle[value] === undefined) {
        throw new StrangeError('Invalid value for TBrushStyle')
    }
    return value as TBrushStyle
}

export type TCanvas = CanvasRenderingContext2D

export enum TFontStyle {
    fsNone = 0,
    fsBold = Math.trunc(1), // 1
    fsItalic = 1 << 1, // 2
    fsUnderline = 1 << 2, // 4
    fsStrikeout = 1 << 3 // 8
}

export type TFontStyles = number

export class TFontStylesOperations {
    static addFontStyle(styles: TFontStyles, style: TFontStyle): TFontStyles {
        return styles | style
    }

    static removeFontStyle(styles: TFontStyles, style: TFontStyle): TFontStyles {
        return styles & ~style
    }

    static toggleFontStyle(styles: TFontStyles, style: TFontStyle): TFontStyles {
        return styles ^ style
    }

    static hasFontStyle(styles: TFontStyles, style: TFontStyle): boolean {
        return (styles & style) !== 0
    }
}

export class TPoint {
    public x: number
    public y: number

    constructor(x: number, y: number) {
        this.x = x
        this.y = y
    }

    // Static method to create a TPoint from another point or similar object
    static from(point: { x: number; y: number }): TPoint {
        return new TPoint(point.x, point.y)
    }

    // Method to set the x and y coordinates
    SetPoint(x: number, y: number): void {
        this.x = x
        this.y = y
    }

    SetLocation(x: number, y: number): void {
        this.SetPoint(x, y)
    }

    // Method to check if two points are equal
    Equals(other: TPoint): boolean {
        return this.x === other.x && this.y === other.y
    }

    // Method to create a string representation of the point
    toString(): string {
        return `(${this.x}, ${this.y})`
    }

    // Additional methods can be added as needed, based on specific requirements
}

export class TRect {
    Left: number
    Top: number
    Right: number
    Bottom: number

    constructor(left: number, top: number, right: number, bottom: number) {
        this.Left = left
        this.Top = top
        this.Right = right
        this.Bottom = bottom
    }

    get Width(): number {
        return Math.abs(this.Right - this.Left)
    }

    get Height(): number {
        return Math.abs(this.Bottom - this.Top)
    }

    IsValidForDrawing(): boolean {
        return this.Width > 0 && this.Height > 0
    }

    // Method to set the rectangle's coordinates
    DoSetRect(left: number, top: number, right: number, bottom: number): void {
        this.Left = left
        this.Top = top
        this.Right = right
        this.Bottom = bottom
    }

    // Method to inflate (or deflate) the rectangle
    InflateRect(left: number, top: number, right: number, bottom: number): void {
        this.Left -= left
        this.Top -= top
        this.Right += right
        this.Bottom += bottom
    }

    PtInRect(point: TPoint): boolean {
        return point.x >= this.Left && point.x <= this.Right && point.y >= this.Top && point.y <= this.Bottom
    }

    // Method to check if a point is within the rectangle
    PtInRect_numbers(x: number, y: number): boolean {
        return x >= this.Left && x <= this.Right && y >= this.Top && y <= this.Bottom
    }

    // Method to check intersection of two rectangles
    Intersect(rect: TRect): TRect {
        const x1 = Math.max(this.Left, rect.Left)
        const y1 = Math.max(this.Top, rect.Top)
        const x2 = Math.min(this.Right, rect.Right)
        const y2 = Math.min(this.Bottom, rect.Bottom)

        if (x2 >= x1 && y2 >= y1) {
            return new TRect(x1, y1, x2, y2)
        }
        return new TRect(0, 0, 0, 0) // No intersection
    }

    // Method to calculate the union of two rectangles
    Union(rect: TRect): TRect {
        const x1 = Math.min(this.Left, rect.Left)
        const y1 = Math.min(this.Top, rect.Top)
        const x2 = Math.max(this.Right, rect.Right)
        const y2 = Math.max(this.Bottom, rect.Bottom)
        return new TRect(x1, y1, x2, y2)
    }

    // Method to check if the rectangle is empty
    IsEmpty(): boolean {
        return this.Width <= 0 || this.Height <= 0
    }

    // Method to check if two rectangles are equal
    Equals(rect: TRect): boolean {
        return (
            this.Left === rect.Left && this.Top === rect.Top && this.Right === rect.Right && this.Bottom === rect.Bottom
        )
    }

    // Method to check if this rectangle contains another rectangle
    Contains(rect: TRect): boolean {
        return this.Left <= rect.Left && this.Top <= rect.Top && this.Right >= rect.Right && this.Bottom >= rect.Bottom
    }

    public static SetRect(left: number, top: number, right: number, bottom: number): TRect {
        return new TRect(left, top, right, bottom)
    }

    // Method to inflate (or deflate) the rectangle
    Inflate(dx: number, dy: number): void {
        this.Left -= dx
        this.Top -= dy
        this.Right += dx
        this.Bottom += dy
    }

    Copy(): TRect {
        return new TRect(this.Left, this.Top, this.Right, this.Bottom)
    }
}

export class TMessage {
    msg: number
    wParam: number | string | null
    lParam: number | string | null
    result: number

    constructor(msg: number, wParam?: number | string, lParam?: number | string, result = 0) {
        this.msg = msg
        this.wParam = wParam || null
        this.lParam = lParam || null
        this.result = result
    }
}

export class THashedStringList {
    private internalMap: Map<string, any> //TODO: use something more efficient here if needed

    constructor() {
        this.internalMap = new Map<string, any>()
    }

    add(key: string): void {
        this.internalMap.set(key, null)
    }

    addObject(key: string, obj: any): void {
        this.internalMap.set(key, obj)
    }

    getObject(key: string): any | undefined {
        return this.internalMap.get(key)
    }

    remove(key: string): boolean {
        return this.internalMap.delete(key)
    }

    containsKey(key: string): boolean {
        return this.internalMap.has(key)
    }

    clear(): void {
        this.internalMap.clear()
    }

    Delete(index: number): void {
        if (index < 0 || index >= this.internalMap.size) {
            throw new StrangeError(`THashedStringList.Delete - Index out of bounds ${index}`)
        }

        let currentIndex = 0
        for (const key of this.internalMap.keys()) {
            if (currentIndex === index) {
                this.internalMap.delete(key)
                return
            }
            currentIndex++
        }
    }

    IndexOf(key: string): number {
        let index = 0
        for (const currentKey of this.internalMap.keys()) {
            if (currentKey === key) {
                return index
            }
            index++
        }
        return -1 // Return -1 if the key is not found, similar to Array.indexOf behavior
    }

    get keys(): string[] {
        return [...this.internalMap.keys()]
    }

    get count(): number {
        return this.internalMap.size
    }
}

//TODO: get rid of these arrays and use normal arrays instead
export class DelphiLikeArray<T> extends Array<T> {
    constructor() {
        super()
    }

    // get items(): T[] {
    //     return this;
    // }

    // public item(index: number): T {
    //     return this[index];
    // }
    SetLength(newLength: number): void {
        if (newLength > this.length) {
            throw new StrangeError('DelphiLikeArray - Increasing the length of the array is not supported')
        }
        this.length = newLength
    }

    add(value: T): void {
        this.push(value)
    }

    insert(index: number, value: T): void {
        this.splice(index, 0, value)
    }

    remove(value: T): boolean {
        const index = this.indexOf(value)
        if (index >= 0) {
            this.splice(index, 1)
            return true
        }
        return false
    }

    removeByIndex(index: number): void {
        this.splice(index, 1)
    }

    extract(value: T): T | null {
        const index = this.indexOf(value)
        if (index >= 0) {
            return this.splice(index, 1)[0]
        }
        return null
    }

    Delete(index: number): void {
        if (index >= 0 && index < this.length) {
            this.splice(index, 1)
        } else {
            throw new StrangeError(`DelphiLikeArray.Delete Index out of bounds ${index}`)
        }
    }

    get LastItem(): T {
        return this[this.length - 1]
    }

    clear(): void {
        this.splice(0, this.length)
    }

    get count(): number {
        return this.length
    }

    get Count(): number {
        return this.length
    }

    public IndexValid(index: number): boolean {
        return this.length > 0 && index >= 0 && index < this.length
    }
}

export class TPngImage {
    protected image: HTMLImageElement
    private imageSourceURL: string

    constructor(imageSourceURL: string) {
        this.imageSourceURL = imageSourceURL
        this.image = new Image()
    }

    Load(): void {
        this.image.src = this.imageSourceURL
    }

    private LoadIfNecessary(): void {
        if (!this.image.src) {
            this.Load()
        }
    }

    public Draw(html_canvas: HTMLCanvasElement, x: number, y: number): void {
        this.LoadIfNecessary()

        const canvasContext = html_canvas.getContext('2d')
        if (!canvasContext) {
            return
        }

        // Drawing the specified image onto the canvas at the specified coordinates
        canvasContext.drawImage(this.image, x, y)
    }

    public Draw_inRect(html_canvas: HTMLCanvasElement, rect: TRect): void {
        this.LoadIfNecessary()

        const canvasContext = html_canvas.getContext('2d')
        if (!canvasContext) {
            return
        }

        // Drawing the specified image onto the canvas within the specified rectangle
        canvasContext.drawImage(this.image, rect.Left, rect.Top, rect.Width, rect.Height)
    }
}
