import React, { FC, useEffect, useMemo } from 'react'
import dayjs from 'dayjs'

import { Button, Flex, Typography, addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'

import styles from '../../profile.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { fetchActiveSubscriptionInfo } from '@root/utils/api'

type Props = {
    subscriptionStatus: string
    chargeDate: Date | null
}

export const SubscriptionInfoActive: FC<Props> = ({ subscriptionStatus, chargeDate }) => {
    const { trialPeriodEnd, isTrialExpired, AccountType } = useAppSelector($getUser)
    const { t } = useTranslation()

    const isCancellableSubscription = useMemo(
        () => (subscriptionStatus !== 'lifetime' || !isTrialExpired) && subscriptionStatus !== 'Unknown',
        [subscriptionStatus]
    )

    const date = useMemo(() => {
        return dayjs(isTrialExpired ? chargeDate : trialPeriodEnd, 'MM/DD/YYYY h:mm A')
            .locale('en')
            .format('MMMM DD, YYYY')
    }, [trialPeriodEnd, chargeDate, isTrialExpired])

    if (!isCancellableSubscription || AccountType === 'BetaTester') return null

    return (
        <Flex direction='column' className={styles.subscriptionInfo}>
            <Flex justifyContent='space-between' className={styles.infoContent}>
                <Flex alignItems='flex-start' direction='column' gap={4} block>
                    <Typography type='h4-bold'>
                        {isTrialExpired ? t(`profile.subscriptionPlans.${subscriptionStatus}`) : t('profile.trial')}
                    </Typography>
                    <Flex alignItems='center' justifyContent='space-between' block>
                        <span>
                            {chargeDate !== null && (
                                <Typography type='interface-regular'>
                                    <Trans
                                        i18nKey='profile.subscriptionInfoTrial'
                                        components={{ date: <Typography type='interface-regular'>{date}</Typography> }}
                                    />
                                </Typography>
                            )}
                        </span>
                        <Button
                            classNames={{ root: styles.infoButton }}
                            label={isTrialExpired ? t('profile.cancelSubscription') : t('profile.suspend')}
                            type='tetriatry-gray'
                            size='compact'
                            onClick={() => {
                                addModal(MODAL_NAMES.userProfile.suspendSubscription)
                            }}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
