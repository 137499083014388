import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { Flex } from '@fto/ui'
import { OptionSettingRef, ViewsSettingsProps } from '../../../types'

import getOptionSettingByConfig from '../../../utils/getOptionSettingByConfig'
import OptionsConfig from './config'
import { Option, SettingsTabRef } from '../../../types'

const ViewsSettings = forwardRef<SettingsTabRef, ViewsSettingsProps>(
    ({ chart, applyToAll, initialData, onDifferentSettingsUpdate }, ref) => {
        const optionsRefs = useRef<OptionSettingRef[]>([])

        const loadOptionsSetting = useCallback((data: any, shouldUpdateConfig = true) => {
            if (optionsRefs.current) {
                for (const ref of optionsRefs.current) {
                    if (ref.name in data) ref.loadOptionSetting(data[ref.name], shouldUpdateConfig)
                }
            }
        }, [])

        const addRef = useCallback((ref: OptionSettingRef) => {
            if (ref && !optionsRefs.current.includes(ref)) {
                optionsRefs.current.push(ref)
            }
        }, [])

        const optionSettingByKey = useCallback(
            (option: Option) => {
                return getOptionSettingByConfig(
                    {
                        tab: 'views',
                        chart,
                        option: option,
                        applyToAll,
                        onDifferentSettingsUpdate,
                        initialData
                    },
                    addRef
                )
            },
            [chart, applyToAll, initialData, onDifferentSettingsUpdate, addRef]
        )

        useImperativeHandle(ref, () => ({ loadOptionsSetting }))

        return (
            <Flex direction='column' block gap={8}>
                {optionSettingByKey(OptionsConfig['Volume'])}
                {optionSettingByKey(OptionsConfig['News'])}
                {optionSettingByKey(OptionsConfig['IndicatorValues'])}
                {optionSettingByKey(OptionsConfig['PeriodSeparators'])}
            </Flex>
        )
    }
)

export default ViewsSettings
