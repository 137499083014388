import { useEffect } from 'react'
import { fireMixpanelEvent } from '@root/utils/api'
import { setLocalStorage } from '@root/utils/localStorage'
import { CONVERSION_PARAMS_FROM_SITES } from '@root/constants/localStorageKeys'

type Props = {
    subscriptionId: number
}

const useUrlParams = ({ subscriptionId }: Props) => {
    useEffect(() => {
        // GET url params from email link

        const params = new URLSearchParams(window.location.search)

        let paramList: { [key: string]: string } = {}

        params.forEach((value, key) => {
            paramList = { ...paramList, [key]: value }
        })

        const cameFromEmail = paramList['utm_source'] && paramList['utm_source'] === 'email'

        if (cameFromEmail) {
            fireMixpanelEvent('fto_email_conversion', { ...paramList })
        }

        // NOTE: HERE we save sendpulse conversion data passed from SITES

        const isPurchaseFromSites = paramList['Landing_subscription_selected']

        if (isPurchaseFromSites) {
            setLocalStorage(CONVERSION_PARAMS_FROM_SITES, paramList)
        }
    }, [])

    useEffect(() => {
        if (subscriptionId) {
            localStorage.removeItem(CONVERSION_PARAMS_FROM_SITES)
        }
    }, [subscriptionId])
}

export default useUrlParams
