import i18n from 'i18next'

import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'
import {
    ACTIVE_SUBSCRIPTION_INFO_URL,
    ALL_SYMBOLS_URL,
    ALL_TEMPLATES_URL,
    apiURL,
    CHANGE_EMAIL_URL,
    CHANGE_PASSWORD_URL,
    CHART_TEMPLATE_URL,
    EMAIL_REGISTER_URL,
    FORGOT_PASSWORD_URL,
    INDICATOR_TEMPLATE_URL,
    PROJECT_BY_ID_URL,
    REFRESH_TOKEN_URL,
    RENEW_SUBSCRIPTION_URL,
    REQUEST_CODE_URL,
    SUSPEND_SUBSCRIPTION_URL,
    SYMBOL_FULL_INFO,
    TELEMETRY_URL,
    TOOL_TEMPLATE_URL
} from '@root/constants/endpoints'
import { AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY } from '@root/constants/localStorageKeys'
import { AUTH_ROUTE } from '@root/constants/routes'
import { RefreshTokenResponse } from '@root/store/auth/auth.types'
import { getBrowserData } from '@root/utils/detectBrowser'
import CommonUtils from '@fto/lib/ft_types/common/BasicClasses/CommonUtils'

export const secureApi = axios.create({ baseURL: apiURL })

if (!CommonUtils.IsInUnitTest) {
    secureApi.interceptors.request.use(
        function (config) {
            const token = localStorage.getItem(AUTH_TOKEN_KEY)
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            } else {
                delete config.headers.Authorization
            }
            return config
        },
        function (error) {
            console.error(error)
            return Promise.reject(error)
        }
    )

    secureApi.interceptors.response.use(
        (response) => {
            if (response.status === 201 || response.status === 200) {
                //app success message
            }
            return response
        },
        async (e: unknown) => {
            const err = e as AxiosError
            const originalRequest = err.config as InternalAxiosRequestConfig<unknown> & { _retry: boolean }
            const isRefreshFailed = err.response?.status === 401 && originalRequest.url === REFRESH_TOKEN_URL

            if (err.message === 'Network Error') {
                throw new Error('Network Error')
            }

            if (err.response) {
                const responseStatus = err.response.status

                if ((responseStatus === 401 && originalRequest!.url === AUTH_ROUTE) || isRefreshFailed) {
                    throw err
                }

                if (responseStatus === 401 && !originalRequest._retry) {
                    const refreshToken = localStorage.getItem(AUTH_REFRESH_TOKEN_KEY)
                    if (!refreshToken) {
                        throw err
                    }
                    originalRequest._retry = true
                    const {
                        data: { id_token }
                    } = await secureApi.post<RefreshTokenResponse>(REFRESH_TOKEN_URL, {
                        refresh_token: refreshToken
                    })
                    secureApi.defaults.headers.common['Authorization'] = `Bearer ${id_token}`
                    localStorage.setItem(AUTH_TOKEN_KEY, id_token)
                    return secureApi(originalRequest)
                }
                throw err.response.data
            }

            throw err
        }
    )
}

//@TODO: create auth slice actions for this methods
export const refresh = (data: { refresh_token: string }) => {
    return secureApi.post(REFRESH_TOKEN_URL, data)
}

export const signUp = (data: object) => {
    return secureApi.post(EMAIL_REGISTER_URL, data)
}

export const requestCode = (data: object) => {
    return secureApi.post(REQUEST_CODE_URL, data)
}

export const changeEmail = (data: { email: string; code: string }) => {
    return secureApi.patch(CHANGE_EMAIL_URL, data)
}

export const changePassword = (data: { password: string; code: string }) => {
    return secureApi.patch(CHANGE_PASSWORD_URL, data)
}

export const forgotPassword = (data: object) => {
    return secureApi.post(FORGOT_PASSWORD_URL, data)
}

export const renewSubscription = (data: { subscriptionId: number }) => {
    return secureApi.put(RENEW_SUBSCRIPTION_URL, data)
}

export const suspendSubscription = (data: { cancellationReasonId: number; subscriptionId: number }) => {
    return secureApi.put(SUSPEND_SUBSCRIPTION_URL, data)
}

export const fetchProjectById = (id: string) => {
    return secureApi.get(`${PROJECT_BY_ID_URL}/${id}`)
}

export const fetchFullSymbolInfo = ({ symbol, broker }: { symbol: string; broker: 'Advanced' }) => {
    return secureApi.get(`${SYMBOL_FULL_INFO}${symbol}?broker=${broker}`)
}

export const fetchAllSymbols = () => {
    return secureApi.get(ALL_SYMBOLS_URL)
}

export const fireMixpanelEvent = (event: string, properties?: any) => {
    const params = properties
        ? {
              ...properties,
              ...getBrowserData(),
              app_language: i18n.language
          }
        : {
              ...getBrowserData(),
              app_language: i18n.language
          }
    return secureApi.post(TELEMETRY_URL, {
        event,
        properties: params
    })
}

export const fetchAllTemplates = () => {
    return secureApi.get(ALL_TEMPLATES_URL)
}

export const createChartTemplate = (data: object) => {
    return secureApi.post(CHART_TEMPLATE_URL, data)
}

export const updateChartTemplate = (templateID: string, data: object) => {
    return secureApi.put(`${CHART_TEMPLATE_URL}/${templateID}`, data)
}

export const deleteChartTemplate = (templateID: string) => {
    return secureApi.delete(`${CHART_TEMPLATE_URL}/${templateID}`)
}

export const createIndicatorTemplate = (indicatorName: string, data: object) => {
    return secureApi.post(`${INDICATOR_TEMPLATE_URL}/${indicatorName}`, data)
}

export const updateIndicatorTemplate = (indicatorName: string, templateID: string, data: object) => {
    return secureApi.put(`${INDICATOR_TEMPLATE_URL}/${indicatorName}/${templateID}`, data)
}

export const deleteIndicatorTemplate = (indicatorName: string, templateID: string) => {
    return secureApi.delete(`${INDICATOR_TEMPLATE_URL}/${indicatorName}/${templateID}`)
}

export const createToolTemplate = (toolName: string, data: object) => {
    return secureApi.post(`${TOOL_TEMPLATE_URL}/${toolName}`, data)
}

export const updateToolTemplate = (toolName: string, templateID: string, data: object) => {
    return secureApi.put(`${TOOL_TEMPLATE_URL}/${toolName}/${templateID}`, data)
}

export const deleteToolTemplate = (toolName: string, templateID: string) => {
    return secureApi.delete(`${TOOL_TEMPLATE_URL}/${toolName}/${templateID}`)
}

export const fetchActiveSubscriptionInfo = () => {
    return secureApi.get(ACTIVE_SUBSCRIPTION_INFO_URL)
}
