import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import cx from 'classnames'

import { IconButton } from '@fto/icons'
import { Flex, Separator, Toggle, Typography } from '@fto/ui'

import { Closable, RibbonOptionType } from '../types'

import styles from '../../../../common.module.scss'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'

type Props = {
    options: Map<number, RibbonOptionType[]>
    name: string
    close: () => void
    additionalElements?: FC<Closable> | ReactNode
    activeOption?: string
}

const LayoutDropdownMenu: FC<Props> = ({ options, name, close, activeOption }) => {
    const handleClick = useCallback(
        (option: RibbonOptionType) => {
            option.action && option.action()
            close()
        },
        [close]
    )

    const rows = useMemo(() => {
        return Array.from(options.entries())
    }, [options])

    return (
        <Flex direction='column' justifyContent='flex-start' gap={4}>
            <Typography className={styles.text} color='gray-600' type='tiny-regular'>
                {name}
            </Typography>
            <Flex direction='column' gap={10}>
                {rows.map(([num, options], idx) => {
                    const isLastRow = idx === rows.length - 1
                    return (
                        <>
                            <Flex direction={'row'} gap={10} alignItems={'center'}>
                                <Typography
                                    className={cx(styles.text, styles.alignSelfCenter)}
                                    color='gray-600'
                                    type='tiny-regular'
                                >
                                    {num}
                                </Typography>
                                <Flex key={num} direction='row' gap={10}>
                                    {options.map((option) => {
                                        const isActive = option.value === activeOption

                                        if (!option.icon) {
                                            return <></>
                                        }

                                        return (
                                            <IconButton
                                                className={cx({ [styles.selected]: isActive })}
                                                key={option.value}
                                                onClick={() => handleClick(option)}
                                                name={option.icon}
                                                size={16}
                                                color={isActive ? 'primary-500' : 'gray-1000'}
                                            />
                                        )
                                    })}
                                </Flex>
                            </Flex>
                            {!isLastRow && <Separator margin='2px' width='100%' color='gray-400' />}
                        </>
                    )
                })}
            </Flex>
        </Flex>
    )
}

export default LayoutDropdownMenu
