import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CONFIRM_EMAIL_ROUTE, LOGIN_ROUTE, makeRoute } from '@root/constants/routes'
import { Button, Checkbox, Flex, InputField, Link, Typography } from '@fto/ui'
import { GoogleAuth } from '@root/pages/Auth/components/Social/Google'
import { IconButton } from '@fto/icons'
import usePasswordField from '@root/hooks/usePasswordField'
import useEmailField from '@root/hooks/useEmailField'
import AuthContainer from '@root/pages/Auth/components/AuthContainer'
import styles from '@root/pages/Auth/styles/auth.module.scss'
import MessageInformer from '@root/components/MessageInformer'
import { statusMessages } from '@root/pages/Auth/constants/errorMessages'
import { MicrosoftAuth } from '@root/pages/Auth/components/Social/Microsoft'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { RESET_AUTH_ERROR } from '@root/store/auth/auth.slice'
import { REGISTER_BY_EMAIL } from '@root/store/auth/auth.actions'
import { CODE_REQUIRED } from '@root/pages/Auth/constants/errorStatuses'
import { fireMixpanelEvent } from '@root/utils/api'
import { saveAuthToken } from '@root/utils/auth'
import { LoginResponse } from '@root/store/auth/auth.types'
import { fireGoogleTagManagerEvent } from '@root/utils/fireGoogleTagManagerEvent'

import { POLICY_LINK, TERMS_LINK } from '../../constants/links'

const Register = () => {
    const { authError: error, isAuthProcess: isLoading } = useAppSelector((state) => state.auth)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const dispatch = useAppDispatch()
    const [policyChecked, setPolicyChecked] = useState(false)

    const {
        password,
        passwordType,
        isInputTypePassword,
        handlePasswordChange,
        handlePasswordInputTypeChange,
        validationData: passwordValidationData
    } = usePasswordField({ initialValue: '' })

    const {
        email,
        handleEmailChange,
        validationData: emailValidationData,
        validate
    } = useEmailField({ initialValue: '' })

    const register = useCallback(async () => {
        const isValid = await validate(email)
        if (!isValid) return

        const fullName = `${firstName} ${lastName}`
        const { payload } = await dispatch(REGISTER_BY_EMAIL({ email, password, type: 'register', fullName }))

        if (payload === CODE_REQUIRED) {
            const hash = window.btoa(encodeURIComponent(`${fullName}:${email}:${password}`))
            return navigate(`${makeRoute(CONFIRM_EMAIL_ROUTE)}?hash=${hash}`)
        }
        // Temporary solution while we don't require email confirmation
        else if (payload && (payload as LoginResponse).idToken) {
            saveAuthToken(payload)
            fireMixpanelEvent('sign_up', { login_method: 'Email' })
            fireGoogleTagManagerEvent('sign_up', { login_method: 'Email' })
            navigate(0)
        }
    }, [email, firstName, lastName, password])

    const submitDisabled = useMemo(() => {
        return (
            !policyChecked || !emailValidationData.isValid || !passwordValidationData.isValid || !firstName || !lastName
        )
    }, [emailValidationData.isValid, passwordValidationData.isValid, firstName, lastName, policyChecked])

    const resetError = () => {
        dispatch(RESET_AUTH_ERROR())
    }

    useEffect(() => {
        fireMixpanelEvent('pageview', { pagename: 'sign_up' })
    }, [])

    return (
        <div className={styles.authPage}>
            <AuthContainer showContent={true}>
                <Flex direction={'column'} gap={24}>
                    <Flex direction={'column'} gap={24}>
                        <Typography
                            type={'h1-bold'}
                            align={'center'}
                            className={styles.authFormTitle}
                            color={'gray-1000'}
                        >
                            {t('auth.signUpTitle')}
                        </Typography>
                        {/* <Typography
                            type={'text-regular'}
                            align={'center'}
                            className={styles.authFormSubTitle}
                            color={'gray-800'}
                        >
                            {t('auth.signUpText')}
                        </Typography> */}
                    </Flex>
                    <Flex direction={'column'} gap={8}>
                        <GoogleAuth type='signUp' />
                        <MicrosoftAuth type='signUp' />
                    </Flex>
                    <div className={styles.authOrLink}>
                        <span>{t('auth.orSignUpLabel')}</span>
                    </div>
                    <form>
                        <Flex direction={'column'} gap={20}>
                            <Flex justifyContent={'space-between'} gap={10}>
                                <InputField
                                    block
                                    type={'text'}
                                    value={firstName}
                                    onChange={setFirstName}
                                    label={t('auth.firstName')}
                                    placeholder={t('auth.firstName')}
                                />
                                <InputField
                                    block
                                    type={'text'}
                                    value={lastName}
                                    onChange={setLastName}
                                    label={t('auth.lastName')}
                                    placeholder={t('auth.lastName')}
                                />
                            </Flex>
                            <InputField
                                isAbsoluteHelperText
                                error={!emailValidationData.isValid}
                                helperText={emailValidationData.errorMessage}
                                block
                                type={'email'}
                                value={email}
                                onChange={handleEmailChange}
                                label={t('auth.email')}
                                placeholder={t('auth.email')}
                            />
                            <InputField
                                isAbsoluteHelperText
                                error={!passwordValidationData.isValid}
                                helperText={passwordValidationData.errorMessage}
                                type={passwordType}
                                value={password}
                                block
                                onChange={handlePasswordChange}
                                label={t('auth.password')}
                                placeholder={t('auth.password')}
                                suffix={
                                    <IconButton
                                        name={isInputTypePassword ? 'eye-off' : 'eye'}
                                        size={16}
                                        onClick={handlePasswordInputTypeChange}
                                    />
                                }
                            />
                            <Flex alignItems='center' className={styles.CheckboxWrapper} gap={8}>
                                <div className={styles.CheckboxWrapper}>
                                    <Checkbox
                                        variant={'default'}
                                        block
                                        checked={policyChecked}
                                        onChange={setPolicyChecked}
                                        className={styles.Checkbox}
                                    />
                                </div>
                                <Typography type='text-regular' className={styles.CheckboxLabel}>
                                    <Trans
                                        i18nKey='auth.agree'
                                        components={{
                                            policyLink: (
                                                <Link target='_blank' href={POLICY_LINK}>
                                                    {t('global.privacyPolicy')}
                                                </Link>
                                            ),
                                            termsLink: (
                                                <Link target='_blank' href={TERMS_LINK}>
                                                    {t('global.termsAndConditions')}
                                                </Link>
                                            )
                                        }}
                                    />
                                </Typography>
                            </Flex>
                            <MessageInformer type='error' isShown={!!error} onClose={resetError} disappearing>
                                <Typography type='interface-medium' color='red-600' align='center' block>
                                    <Trans
                                        //@ts-ignore
                                        i18nKey={statusMessages[error]}
                                        components={{
                                            supportLink: (
                                                <Link target='_blank' href='mailto:support@forextester.com'>
                                                    support@forextester.com
                                                </Link>
                                            )
                                        }}
                                    />
                                </Typography>
                            </MessageInformer>
                            <Button
                                type={'marketing'}
                                loading={isLoading}
                                disabled={submitDisabled}
                                block
                                onClick={register}
                                label={t('auth.signUp')}
                            />
                        </Flex>
                    </form>
                    <Flex justifyContent={'center'}>
                        <Typography type={'text-regular'}>{t('auth.haveAccount')}</Typography> &nbsp;
                        <Link to={makeRoute(LOGIN_ROUTE)}>{t('auth.signIn')}</Link>
                    </Flex>
                </Flex>
            </AuthContainer>
        </div>
    )
}
export default Register
