import StrangeError from '../common/common_errors/StrangeError'
import StrangeSituationNotifier from '../common/StrangeSituationNotifier'
import { DelphiColors, GetPenStyle, TColor, TPenStyle } from '../delphi_compatibility/DelphiBasicTypes'
import { ColorHelperFunctions } from '../drawing_interface/ColorHelperFunctions'
import { TLineStyle } from '../drawing_interface/vclCanvas'
import { TDrawStyle } from '../extension_modules/indicators/api/IndicatorInterfaceUnit'
import { TMyObjectList } from '../ft_types/common/Common'
import { StrsConv } from '../ft_types/common/StrsConv'
import { IGPPen, IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'

export enum TIndexPos {
    ip_Valid,
    ip_Invalid,
    ip_InvBefore,
    ip_InvAfter
}

export enum TGraphLineStyle {
    ls_Vertical,
    ls_Horizontal,
    ls_Ray,
    ls_Common
}

export interface LevelType {
    value: string | null
    style: TPenStyle | null
    width: number | null
    color: TColor | null
    opacity: number | null
    text: string | null
    id: number | string
    isActive: boolean
}

export enum LevelActiveType {
    inactive,
    active
}

export class TLineStyleRec {
    color: TColor // line color
    style: TPenStyle // line style
    width: number // line width
    DrawingStyle: TDrawStyle // drawing style on chart
    Symbol!: number // symbol index for ds_Symbol
    xoffs!: number // x and y shift of symbol in pixels
    yoffs!: number

    constructor() {
        this.color = DelphiColors.clRed
        this.style = TPenStyle.psSolid
        this.width = 1
        this.DrawingStyle = TDrawStyle.ds_Line
    }

    GetShortStr(isNeedToShowLineProperties: boolean): string {
        if (isNeedToShowLineProperties) {
            return `${StrsConv.StrColor(this.color)},${this.style},${this.width}`
        }
        return `${StrsConv.StrColor(this.color)}`
    }

    SetShortStr(s: string): void {
        const parts = s.split(',')
        if (parts.length !== 3) {
            if (parts.length === 1) {
                this.color = ColorHelperFunctions.FixColor(parts[0])
                return
            } else {
                throw new StrangeError('SetShortStr - Invalid string format.')
            }
        }

        this.color = ColorHelperFunctions.FixColor(parts[0])

        // Converting string to integer for style
        this.style = parseInt(parts[1])

        // Converting string to integer for width
        this.width = parseInt(parts[2])
    }
}

// Make the Level class implement the LevelType interface
export class Level implements LevelType {
    value: string | null = null
    style: TPenStyle | null = null
    width: number | null = null
    color: TColor | null = null
    opacity: number | null = 1
    text: string | null = null
    id: string | number = ''
    isActive = true

    constructor() {
        this.value = ''
        this.style = TPenStyle.psSolid
        this.width = 1
        this.color = DelphiColors.clBlack
        this.opacity = 1
    }

    LevelFromString(s: string): Level[] | void {
        if (s === '') {
            return []
        }
        const levelList: Level[] = []
        const levelStrings = s.match(/{(.*?)}/g) || []
        for (const levelStr of levelStrings) {
            const level = new Level()
            const parts =
                levelStr
                    .slice(1, -1)
                    .match(/(".*?"|\S+)/g)
                    ?.map((part) => part.replaceAll('"', '')) || []
            if (parts.length >= 6) {
                level.value = parts[0]
                level.text = parts[1]
                level.color = parts[2]
                level.style = parseInt(parts[3])
                level.width = parseInt(parts[4])
                level.id = parseInt(parts[5])
                level.isActive = parseInt(parts[6]) === 1
            }
            levelList.push(level)
        }
        return levelList
    }
    LevelToString(levels: Level[]): string {
        return levels
            .map((level) => {
                const text = level.text ? `"${level.text}"` : '"n"'
                const value = `${level.value}`
                return `{${[value, text, level.color, level.style, level.width, level.id, level.isActive ? 1 : 0].join(
                    ' '
                )}}`
            })
            .join('')
    }
}

export class TLevelData {
    public value!: number
    public text!: string
    public style!: TLineStyle
    public pen: IGPPen
    public brush: IGPSolidBrush
    public id: string | number = TLevelData.getNextId()
    public isActive: LevelActiveType = 1 // 1 - drawing, 0 - not drawing
    private static currentId = 0

    constructor(s: string, _style?: TLineStyle, opacity = 1) {
        if (_style) {
            this.style = _style
        } else {
            this.style = new TLineStyle('#000000', TPenStyle.psSolid, 1)
            this.SetLevelFromStr(s)
        }
        this.pen = new IGPPen(this.style.color, this.style.width, opacity)
        this.pen.setPenStyle(this.style.style)
        this.brush = new IGPSolidBrush(this.style.color)
    }

    clone(): TLevelData {
        const styleClone = this.style.clone()
        const levelDataClone = new TLevelData(this.text, styleClone)
        levelDataClone.value = this.value
        levelDataClone.text = this.text
        levelDataClone.id = this.id
        levelDataClone.isActive = this.isActive
        return levelDataClone
    }

    private static getNextId(): number {
        return this.currentId++
    }

    // New static methods for TLevelData creation
    public static Create_TLevelData(
        aValue: number,
        s: string,
        _style: TPenStyle,
        width: number,
        clr: TColor
    ): TLevelData {
        const res = new TLevelData(s)
        res.value = aValue
        res.text = s
        res.style = new TLineStyle(clr, _style, width) // Assuming TLineStyle has these properties
        return res
    }

    public static Create_TLevelData_Style(
        aValue: number,
        s: string,
        _style: TLineStyle,
        isActive = LevelActiveType.active,
        opacity = 1
    ): TLevelData {
        const res = new TLevelData(s, _style, opacity)
        res.value = aValue
        res.text = s
        res.isActive = isActive
        return res
    }

    GetLevelStr(precision: number): string {
        // Check if the necessary functions and properties exist
        if (this.value === undefined) {
            throw new StrangeError('Value is undefined')
        }

        if (!this.text) {
            throw new StrangeError('Text is not defined or empty')
        }

        if (!this.style) {
            throw new StrangeError('Style is not defined')
        }

        if (this.style?.color === undefined) {
            throw new TypeError('Style color is undefined')
        }

        if (this.style?.style === undefined) {
            throw new TypeError('Style style is undefined')
        }

        if (this.style?.width === undefined) {
            throw new TypeError('Style width is undefined')
        }

        const rgbColor = StrsConv.StrColor(this.style.color)

        // Return the formatted string using template literals for better readability
        return `${StrsConv.StrDouble(this.value, precision)} ${StrsConv.QuoteStr(this.text)} ${rgbColor} ${
            this.style.style
        } ${this.style.width} ${this.id} ${this.isActive}`
    }

    // Improved the implementation by removing the need for a 'remainingStr' variable.
    // Instead, we directly pass the updated string to the next parsing function.
    // This makes the code cleaner and easier to follow.
    SetLevelFromStr(s: string): void {
        let remainingStr: string

            // Extract the double value and update the string for the next parsing step.
        ;[this.value, remainingStr] = StrsConv.GetStrDouble(s, ' ')

        // Extract the quoted text.
        let textStr: string
        ;[textStr, remainingStr] = StrsConv.GetSubStr(remainingStr, ' ')
        this.text = StrsConv.GetQuotedStr(textStr, '"', '"')

        // Extract the color value and update the string for the next parsing step.
        // Also, ensure that the color is converted from BGR to RGB if it's a hex value.
        ;[this.style.color, remainingStr] = StrsConv.GetStrColor(remainingStr, ' ')

        // Extract the pen style as an integer and cast it to TPenStyle.
        // Update the string for the next parsing step.
        let styleNumber: number
        ;[styleNumber, remainingStr] = StrsConv.GetStrInt(remainingStr, ' ')
        this.style.style = GetPenStyle(styleNumber)

        // Extract the width as an integer.
        ;[this.style.width, remainingStr] = StrsConv.GetStrInt(remainingStr, ' ')
        ;[this.id, remainingStr] = StrsConv.GetStrInt(remainingStr, ' ')
        ;[this.isActive, remainingStr] = StrsConv.GetStrInt(remainingStr, ' ')
    }
}

export class TLevelsList extends TMyObjectList<TLevelData> {
    clone(): TLevelsList {
        const clonedList = new TLevelsList()
        for (let i = 0; i < this.Count; i++) {
            clonedList.Add(this[i].clone())
        }
        return clonedList
    }

    Assign(list: TLevelsList): void {
        this.Clear()
        for (let i = 0; i < list.Count; i++) {
            const levelData = list[i]
            this.Add(
                TLevelData.Create_TLevelData_Style(levelData.value, levelData.text, levelData.style, levelData.isActive)
            )
        }
    }

    ImportFromStr(s: string): void {
        this.Clear()

        let startIndex = 0
        let s1: string
        while (startIndex < s.length) {
            s1 = StrsConv.GetQuotedStr(s.slice(Math.max(0, startIndex)), '{', '}')
            if (s1 === '') {
                break
            } else {
                try {
                    this.Add(new TLevelData(s1))
                } catch (error) {
                    StrangeSituationNotifier.NotifyAboutUnexpectedSituation(error as Error)
                }

                startIndex += s.slice(Math.max(0, startIndex)).indexOf(`{${s1}}`) + s1.length + 2
            }
        }
    }

    ExportToStr(precision: number): string {
        let result = ''
        for (let i = 0; i < this.Count; i++) {
            result += StrsConv.QuoteStr(this[i].GetLevelStr(precision), '{', '}')
        }
        return result
    }

    public Item(index: number): TLevelData {
        return this[index]
    }
    SortByValue(): void {
        this.sort((a, b) => a.value - b.value)
    }
}

export class GraphicObjects {}
