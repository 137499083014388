import { ConfigType } from '../../../types'

const ViewsConfig: { [key: string]: any } = {
    Volume: {
        key: 'Volume',
        type: 'checkboxAndColorSetting',
        name: 'settingsModal.volume',
        colorKey: 'VolumeColor',
        colorConfigType: ConfigType.ColorScheme,
        booleanKey: 'ShowVolume',
        booleanConfigType: ConfigType.ChartOptions
    },
    News: {
        key: 'News',
        type: 'checkboxSetting',
        name: 'settingsModal.news',
        booleanKey: 'ShowNews',
        booleanConfigType: ConfigType.ChartOptions
    },
    IndicatorValues: {
        key: 'IndicatorValues',
        type: 'checkboxSetting',
        name: 'settingsModal.indicators',
        booleanKey: 'ShowIndicatorValues',
        booleanConfigType: ConfigType.ChartOptions
    },
    PeriodSeparators: {
        key: 'PeriodSeparators',
        type: 'checkboxAndColorSetting',
        name: 'settingsModal.periodSeparators',
        colorKey: 'PeriodSeparatorsColor',
        colorConfigType: ConfigType.ColorScheme,
        booleanKey: 'ShowPeriodSeparators',
        booleanConfigType: ConfigType.ChartOptions
    }
}

export default ViewsConfig
