import { ConfigType } from '../../../types'

const Config: { [key: string]: any } = {
    OffsetPercentage: {
        key: 'OffsetPercentage',
        type: 'sliderSetting',
        name: 'settingsModal.offset',
        valueKey: 'OffsetPercentage',
        valueConfigType: ConfigType.ChartOptions,
        maxValue: 100,
        divisionFactor: 100,
        withInputField: true
    },
    BackgroundColor: {
        key: 'BackgroundColor',
        type: 'colorSetting',
        name: 'settingsModal.backgroundColor',
        colorKey: 'BackgroundColor',
        colorConfigType: ConfigType.ColorScheme
    },
    FrameAndTextColor: {
        key: 'FrameAndTextColor',
        type: 'colorSetting',
        name: 'settingsModal.text',
        colorKey: 'FrameAndTextColor',
        colorConfigType: ConfigType.ColorScheme
    },
    Grid: {
        key: 'Grid',
        type: 'checkboxAndColorSetting',
        name: 'settingsModal.grid',
        colorKey: 'GridColor',
        colorConfigType: ConfigType.ColorScheme,
        booleanKey: 'ShowGrid',
        booleanConfigType: ConfigType.ChartOptions
    },
    CandleUp: {
        key: 'CandleUp',
        type: 'colorSetting',
        name: 'settingsModal.candleUp',
        colorKey: 'CandleUpColor',
        colorConfigType: ConfigType.ColorScheme
    },
    CandleDown: {
        key: 'CandleDown',
        type: 'colorSetting',
        name: 'settingsModal.candleDown',
        colorKey: 'CandleDownColor',
        colorConfigType: ConfigType.ColorScheme
    },
    FillerUp: {
        key: 'FillerUp',
        type: 'colorSetting',
        name: 'settingsModal.fillerUp',
        colorKey: 'CandleUpFillerColor',
        colorConfigType: ConfigType.ColorScheme
    },
    FillerDown: {
        key: 'FillerDown',
        type: 'colorSetting',
        name: 'settingsModal.fillerDown',
        colorKey: 'CandleDownFillerColor',
        colorConfigType: ConfigType.ColorScheme
    },
    CandleUpShadowColor: {
        key: 'CandleUpShadowColor',
        type: 'colorSetting',
        name: 'settingsModal.shadowUp',
        colorKey: 'CandleUpShadowColor',
        colorConfigType: ConfigType.ColorScheme
    },
    CandleDownShadowColor: {
        key: 'CandleDownShadowColor',
        type: 'colorSetting',
        name: 'settingsModal.shadowDown',
        colorKey: 'CandleDownShadowColor',
        colorConfigType: ConfigType.ColorScheme
    },
    BuyMarker: {
        key: 'BuyMarker',
        type: 'colorSetting',
        name: 'settingsModal.buyMarker',
        colorKey: 'BuyMarkerColor',
        colorConfigType: ConfigType.ColorScheme
    },
    SellMarker: {
        key: 'SellMarker',
        type: 'colorSetting',
        name: 'settingsModal.sellMarker',
        colorKey: 'SellMarkerColor',
        colorConfigType: ConfigType.ColorScheme
    },
    StopLoss: {
        key: 'StopLoss',
        type: 'colorSetting',
        name: 'settingsModal.stopLoss',
        colorKey: 'StopLossColor',
        colorConfigType: ConfigType.ColorScheme
    },
    TakeProfit: {
        key: 'TakeProfit',
        type: 'colorSetting',
        name: 'settingsModal.takeProfit',
        colorKey: 'TakeProfitColor',
        colorConfigType: ConfigType.ColorScheme
    },
    ProfitTransaction: {
        key: 'ProfitTransaction',
        type: 'colorSetting',
        name: 'settingsModal.profitTransaction',
        colorKey: 'ProfitTransactionColor',
        colorConfigType: ConfigType.ColorScheme
    },
    LossTransaction: {
        key: 'LossTransaction',
        type: 'colorSetting',
        name: 'settingsModal.lossTransaction',
        colorKey: 'LossTransactionColor',
        colorConfigType: ConfigType.ColorScheme
    },
    AskLevel: {
        key: 'AskLevel',
        type: 'checkboxAndColorSetting',
        name: 'settingsModal.askLevel',
        colorKey: 'AskColor',
        colorConfigType: ConfigType.ColorScheme,
        booleanKey: 'ShowAskLevel',
        booleanConfigType: ConfigType.ChartOptions
    },
    BidLevel: {
        key: 'BidLevel',
        type: 'checkboxAndColorSetting',
        name: 'settingsModal.bidLevel',
        colorKey: 'BidColor',
        colorConfigType: ConfigType.ColorScheme,
        booleanKey: 'ShowBidLevel',
        booleanConfigType: ConfigType.ChartOptions
    }
}

export default Config
